import cx from 'classnames';
import { useContext } from 'react';

import MenuContext from 'components/MenuContext';

type ApplicationFormSidebarProps = {
  children: React.ReactNode;
};

const ApplicationFormSidebar = (props: ApplicationFormSidebarProps) => {
  const { isMenuLockedOpen } = useContext(MenuContext);

  return (
    <div
      className={cx(
        'flex-grow-0 flex-shrink-0 hidden sticky top-menubar md:block h-[calc(100vh-var(--menu-bar-height))] overflow-auto py-lg border-grey-10 border-r-1 transition-spacing duration-300 u-hide-scrollbar',
        {
          'w-[232px]': isMenuLockedOpen,
          'w-[344px]': !isMenuLockedOpen,
        }
      )}
    >
      {props.children}
    </div>
  );
};

export default ApplicationFormSidebar;
