import { createContext, Dispatch, SetStateAction } from 'react';

export type AnalyticsContextProps = {
  hasAcceptedAnalytics: boolean;
  setHasAcceptedAnalytics?: Dispatch<SetStateAction<boolean>>;
  hasAcceptedMarketing: boolean;
  setHasAcceptedMarketing?: Dispatch<SetStateAction<boolean>>;
  cookieControl: any;
  setCookieControl?: Dispatch<SetStateAction<any>>;
};

export const AnalyticsContext = createContext<AnalyticsContextProps>({
  hasAcceptedAnalytics: false,
  setHasAcceptedAnalytics: undefined,
  hasAcceptedMarketing: false,
  setHasAcceptedMarketing: undefined,
  cookieControl: null,
  setCookieControl: undefined,
});

export default AnalyticsContext;
