import Head from 'next/head';

const DEFAULTS = {
  title: 'Wellcome Funding',
  description:
    'Apply for research funding on Wellcome Funding. Check which funding rounds are open for applications and apply online.',
};

type MetaProps = {
  title?: string | string[];
  description?: string;
};

export const Meta = (props: MetaProps) => {
  const title = [props.title, DEFAULTS.title].flat().join(' | ');

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" key="description" content={props.description || DEFAULTS.description} />
    </Head>
  );
};

export default Meta;
