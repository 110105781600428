import { createContext, ReactNode, useEffect, useState } from 'react';

type ViewportContextProps = {
  isMainContentScrollable: boolean;
  setIsMainContentScrollable: (bool: boolean) => void;
  isPhone: boolean;
  setIsPhone: (bool: boolean) => void;
  isMobile: boolean;
  setIsMobile: (bool: boolean) => void;
  isLarge: boolean;
  setIsLarge: (bool: boolean) => void;
  isTouchDevice: boolean;
  setIsTouchDevice: (bool: boolean) => void;
};

type ProviderProps = {
  children: ReactNode;
};

const mediaQueries = {
  xsmall: '(max-width: 414px)',
  smallMax: '(max-width: 767px)',
  smallMin: '(min-width: 768px)',
  mediumMax: '(max-width: 1023px)',
  mediumMin: '(min-width: 1024px)',
  large: '(min-width: 1200px)',
  xlarge: '(min-width: 1344px)',
  xxlarge: '(min-width: 1500px)',
  landscape: '(orientation: landscape)',
  portrait: '(orientation: portrait)',
};

const defaultState = {
  isMainContentScrollable: true,
  setIsMainContentScrollable: () => {},
  isPhone: true,
  setIsPhone: () => {},
  isMobile: true,
  setIsMobile: () => {},
  isLarge: true,
  setIsLarge: () => {},
  isTouchDevice: false,
  setIsTouchDevice: () => {},
};

const testIsTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const ViewportContext = createContext<ViewportContextProps>(defaultState);

export const ViewportContextProvider = ({ children }: ProviderProps) => {
  const [state, setState] = useState<ViewportContextProps>({
    isMainContentScrollable: true,
    setIsMainContentScrollable: (bool) => setState((prevState) => ({ ...prevState, isMainContentScrollable: bool })),
    isPhone: true,
    setIsPhone: (bool) => setState((prevState) => ({ ...prevState, isPhone: bool })),
    isMobile: true,
    setIsMobile: (bool) => setState((prevState) => ({ ...prevState, isMobile: bool })),
    isLarge: true,
    setIsLarge: (bool) => setState((prevState) => ({ ...prevState, isLarge: bool })),
    isTouchDevice: false,
    setIsTouchDevice: (bool) => setState((prevState) => ({ ...prevState, isTouchDevice: bool })),
  });

  /**
   * useEffect hook with empty array argument is equivalent of
   * componentDidMount lifecycle method
   * used here to provide client side functionality
   * for setting up media query list (MQL) listener
   */
  useEffect(() => {
    const mqlp: MediaQueryList = window.matchMedia(mediaQueries.smallMax);
    const mqlm: MediaQueryList = window.matchMedia(mediaQueries.mediumMax);
    const mqll: MediaQueryList = window.matchMedia(mediaQueries.large);

    const handlerPhone = (event: MediaQueryListEvent) => {
      state.setIsPhone(event.matches);
    };

    const handlerMobile = (event: MediaQueryListEvent) => {
      state.setIsMobile(event.matches);
    };

    const handlerLarge = (event: MediaQueryListEvent) => {
      state.setIsLarge(event.matches);
    };

    // Set initial state
    state.setIsPhone(mqlp.matches);
    state.setIsMobile(mqlm.matches);
    state.setIsLarge(mqll.matches);
    state.setIsTouchDevice(testIsTouchDevice());

    /**
     * IMPORTANT: while addListener is deprecated, and
     * it's generally recommended to replace this with
     * addEventListener, MediaQueryList does not inherit
     * from EventTarget on Safari < 14 - and thus causes an
     * infinite loop and the site to fail to render. So this
     * should not be updated until addListener is actually removed
     * from current browsers.
     *
     * Issue described here: https://github.com/mdn/sprints/issues/858
     */

    if (typeof mqlm.addEventListener !== 'undefined') {
      mqlp.addEventListener('change', handlerPhone);
      mqlm.addEventListener('change', handlerMobile);
      mqll.addEventListener('change', handlerLarge);

      // Remove listener on cleanup
      return () => {
        mqlp.removeEventListener('change', handlerPhone);
        mqlm.removeEventListener('change', handlerMobile);
        mqll.removeEventListener('change', handlerLarge);
      };
    }
    mqlp.addListener(handlerPhone);
    mqlm.addListener(handlerMobile);
    mqll.addListener(handlerLarge);

    // Remove listener on cleanup
    return () => {
      mqlp.removeListener(handlerPhone);
      mqlm.removeListener(handlerMobile);
      mqll.removeListener(handlerLarge);
    };

    // Empty array as final argument ensures effect is only run on mount and unmount
  }, []);

  return <ViewportContext.Provider value={state}>{children}</ViewportContext.Provider>;
};

ViewportContext.displayName = 'ViewportContext';

export default ViewportContext;
