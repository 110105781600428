import cx from 'classnames';

type GridProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
};

export const Grid = (props: GridProps) => (
  <div
    className={cx(
      'grid grid-cols-6 sm:grid-cols-12 auto-rows-max gap-x-[12px] sm:gap-x-[19px] md:gap-x-[40px] lg:gap-x-[48px]',
      props.className
    )}
    id={props.id}
  >
    {props.children}
  </div>
);

type GridCellSpan = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | 'full';
type GridCellProps = {
  className: `col-span-${GridCellSpan}${string}` | `col-auto${string}`;
  children: React.ReactNode;
};

export const GridCell = (props: GridCellProps) => <div className={props.className}>{props.children}</div>;
