import { ApiResponse } from './api/types';
import CONSTANTS from './constants';

export const isApiError = (
  errors: ApiResponse['errors'],
  errorCode: (typeof CONSTANTS.API_ERROR_CODES)[keyof typeof CONSTANTS.API_ERROR_CODES]
) => {
  try {
    return errors.some((error) => error.code === errorCode);
  } catch {
    return undefined;
  }
};

export default isApiError;
