import useApplicationOverview from './useApplicationOverview';
import useApplicationParams from './useApplicationParams';

export const useApplicationRole = () => {
  const { formId, applicationId } = useApplicationParams();
  const canUseApplicationData = Boolean(formId && applicationId);

  /**
   * Use the application overview endpoint here because it's already widely used, and therefore more likely
   * to be cached. We could also use the application endpoint, but that would require a new API call in
   * most cases.
   */
  const { data, isLoading } = useApplicationOverview(canUseApplicationData && `${formId}/${applicationId}`);

  return {
    role: data?.currentUserRole,
    isLoading,
  };
};

export default useApplicationRole;
