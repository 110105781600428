import useSWR from 'swr';

import { getExpertReview } from 'utils/api/expert-review';
import CONSTANTS from 'utils/constants';
import { isApiError } from 'utils/is-api-error';

export const useExpertReview = (id: string) => {
  const response = useSWR(id ? `api/expert-review/${id}` : null, () => getExpertReview(id));

  return {
    ...response,
    data: response.data?.data,
    isLoading: !response.data && !response.error,
    notFound: isApiError(response.data?.errors, CONSTANTS.API_ERROR_CODES.notFound),
  };
};

export default useExpertReview;
