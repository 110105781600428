import useApplicationOverview from './useApplicationOverview';

import { submissionStatusesSf } from 'utils/api/application';

export const useSubmissionStatus = (formId: string, applicationId: string) => {
  const { data: overview } = useApplicationOverview(formId && applicationId && `${formId}/${applicationId}`);

  const isReturnedToApplicantFromRO = overview?.submissionStatus === submissionStatusesSf.returnedToApplicant;
  const isReturnedToApplicantFromWellcome =
    overview?.submissionStatus === submissionStatusesSf.returnedToApplicantFromWellcome;
  const isWithApplicant =
    overview?.submissionStatus === submissionStatusesSf.withApplicant ||
    isReturnedToApplicantFromRO ||
    isReturnedToApplicantFromWellcome;

  const isWithWellcome = overview?.submissionStatus === submissionStatusesSf.withWellcome;
  const isWithResearchOffice = overview?.submissionStatus === submissionStatusesSf.withResearchOffice;

  const isStarted = overview?.displayStatus === 'STARTED';

  /*
   * When an application moves past the 'STARTED' status, it should always have the 'isWithWellcome' submission status.
   * However, Salesforce currently doesn't enforce this, so here we always return 'true' for 'isWithWellcome' and 'false'
   * for all other statuses if the application no longer has the 'STARTED' status.
   */
  return {
    isWithApplicant: isWithApplicant && isStarted,
    isWithResearchOffice: isWithResearchOffice && isStarted,
    isWithWellcome: isWithWellcome || !isStarted,
    isReturnedToApplicantFromRO: isReturnedToApplicantFromRO && isStarted,
    isReturnedToApplicantFromWellcome: isReturnedToApplicantFromWellcome && isStarted,
  };
};

export default useSubmissionStatus;
