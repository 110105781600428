/**
 * Returns the current section and a `sectionIndex` representing the position of the section in a sorted array storing all sections
 */

import useApplicationOverview from './useApplicationOverview';
import useApplicationParams from './useApplicationParams';

export const useSectionData = (sectionId: string, isStaticSection = false) => {
  const { formId, applicationId } = useApplicationParams();
  const { data: overview } = useApplicationOverview(formId && applicationId ? `${formId}/${applicationId}` : undefined);

  const formProperty = isStaticSection ? overview?.staticForm : overview?.form;

  const flatSections = formProperty?.pageGroups?.flatMap((pageGroup) => pageGroup.pages) || [];
  const sectionIndex = flatSections?.findIndex((item) => item.id === sectionId);
  const section = flatSections[sectionIndex];

  return { section, sectionIndex };
};

export default useSectionData;
