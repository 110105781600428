import { ReactNode, useState } from 'react';

import { FormAutoSaveContext } from './FormAutoSaveContext';
import type { ContextProps } from './FormAutoSaveContext';

import { ApiResponse } from 'utils/api/types';

type FormAutoSaveContextProviderProps = {
  formId?: string;
  rootId?: string;
  children: ReactNode;
  onFormFieldValueChange?: () => void;
  customSaveFunction?: (formDataRowToSave: any, allFormData: any, allFormErrors: any) => Promise<ApiResponse>;
};

export const FormAutoSaveContextProvider = ({
  formId,
  rootId,
  children,
  onFormFieldValueChange,
  customSaveFunction,
}: FormAutoSaveContextProviderProps) => {
  // Keeps track of the number of pending PATCH/POST/DELETE form API calls
  const [savingCounter, setSavingCounter] = useState<ContextProps['savingCounter']>(0);
  const [hasSaved, setHasSaved] = useState<ContextProps['hasSaved']>(false);
  const [lastSavedAtTime, setLastSavedAtTime] = useState<ContextProps['lastSavedAtTime']>(undefined);
  const [hasSavingError, setHasSavingError] = useState<ContextProps['hasSavingError']>(false);

  // Increments/decrements savingCounter
  const setIsSaving = (saving: boolean) => {
    if (saving) setSavingCounter((prevIsSaving) => prevIsSaving + 1);
    else setSavingCounter((prevIsSaving) => prevIsSaving - 1);
  };

  return (
    <FormAutoSaveContext.Provider
      value={{
        hasSaved,
        setHasSaved,
        savingCounter,
        setIsSaving,
        lastSavedAtTime,
        setLastSavedAtTime,
        hasSavingError,
        setHasSavingError,
        shouldAutoSave: true,
        formId,
        rootId,
        onFormFieldValueChange,
        customSaveFunction,
      }}
    >
      {children}
    </FormAutoSaveContext.Provider>
  );
};

export default FormAutoSaveContextProvider;
