import { Icon } from '@wellcometrust/design-system';
import { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import cx from 'classnames';
import { ElementType } from 'react';

import Button from '../Button';

import { NotificationProps, NotificationVariants } from './shared-notification-configuration';

type ToastProps = NotificationProps & {
  onClose: () => void;
  titleElement?: ElementType;
  toastId?: string;
};

const Toast = (props: ToastProps) => {
  const TitleElement = props.titleElement || 'h3';

  return (
    <div
      className={cx(
        'relative p-staticSm sm:p-staticMd w-[288px] sm:w-[336px] bg-white shadow-[4px_8px_16px_rgba(41,41,41,0.1)] border-1 border-solid border-grey-10 rounded-sm',
        props.className
      )}
      role="alert"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div className="flex items-center mb-staticXs">
        <div className="flex-none mr-staticXs">
          <div className={cx('p-[10px] rounded-full', NotificationVariants[props.notificationVariant].iconHighlight)}>
            <Icon
              name={
                props?.customIcon?.name
                  ? props.customIcon.name
                  : (NotificationVariants[props.notificationVariant].defaultIcon as IconProps['name'])
              }
              className={cx(
                NotificationVariants[props.notificationVariant].darkTintedText,
                props?.customIcon?.className
              )}
            />
          </div>
        </div>
        {props.title && (
          <TitleElement
            className={cx(
              'text-heading-sm font-bold m-0 flex flex-1',
              NotificationVariants[props.notificationVariant].darkTintedText,
              {
                [props.title.className]: props.title.className,
                'pr-[28px] sm:pr-[20px] lg:pr-0': props.onClose,
              }
            )}
          >
            {' '}
            {props.title.text}
          </TitleElement>
        )}
      </div>
      {props.children && (
        <div
          className={cx('text-body-md text-grey-80 break-words', {
            'mb-staticXs': props.action,
            'mb-0': !props.action,
          })}
        >
          {props.children}
        </div>
      )}
      {props.action && (
        <span className="flex flex-none flex-col justify-items-center sm:ml-auto basis-full sm:basis-auto">
          <span className="my-auto">
            <Button
              className={cx('min-h-[0px] mb-[-4px]', props.action?.className)}
              href={props.action?.href}
              onClick={props.action?.onClick}
              type="button"
              variant="inline"
            >
              {props.action?.text}
            </Button>
          </span>
        </span>
      )}

      <button
        onClick={props.onClose}
        type="button"
        className="min-h-thumb min-w-thumb flex flex-col justify-items-center absolute top-staticXs right-staticXs hover:bg-grey-05 transition-color duration-300 ease-in-out"
      >
        <Icon name="iconActionCross" className="m-auto" />
        <span className="sr-only">Close banner</span>
      </button>
    </div>
  );
};

export default Toast;
