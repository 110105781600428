import cx from 'classnames';
import { useContext } from 'react';

import AnalyticsContext from 'components/AnalyticsContext';
import Button from 'components/Button';
import FancyLink from 'components/FancyLink';
import { Container } from 'components/NewLayout';
import WellcomeBrandLogo from 'components/WellcomeBrandLogo';

import URL_LIBRARY from 'utils/url-library';

type ExtendedFooterProps = {
  className?: string;
};

export const ExtendedFooter = (props: ExtendedFooterProps) => {
  const { cookieControl } = useContext(AnalyticsContext);

  const handleShowCivic = () => {
    cookieControl?.open();
  };

  return (
    <footer
      className={cx(
        'bg-amber-10 py-staticLg md:py-staticXl c-site-footer mt-auto z-10 relative translate-z-1 u-scroll-lock-compensation',
        {
          [props.className]: props.className,
        }
      )}
    >
      <Container maxWidth="md">
        <section>
          <div className="grid sm:grid-cols-12 sm:gap-x-staticXxl">
            <div className="sm:col-span-6">
              <h2 className="text-body-md font-medium mb-staticMd">How Wellcome grant funding works</h2>

              <ul className="text-body-sm list-none ml-0 pl-0">
                <li className="mb-staticMd">
                  <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.GRANT_FUNDING_GUIDANCE}>
                    Grant funding guidance
                  </FancyLink>
                </li>
                <li className="mb-staticMd">
                  <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.GRANT_CONDITIONS}>Grant conditions</FancyLink>
                </li>
                <li className="mb-staticMd">
                  <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.GRANT_FUNDING_POLICIES}>
                    Grant funding policies
                  </FancyLink>
                </li>
                <li className="mb-staticMd">
                  <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.HOW_TO_WRITE_A_GRANT_APPLICATION}>
                    How to write Wellcome grant applications
                  </FancyLink>
                </li>
                <li>
                  <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.DISABILITY_SUPPORT}>
                    Disability-related support for applicants
                  </FancyLink>
                </li>
              </ul>
            </div>

            <div className="sm:col-span-6 mt-staticXl sm:mt-0">
              <h2 className="text-body-md font-medium mb-staticMd">Get help or report a problem</h2>

              <ul className="text-body-sm list-none ml-0 pl-0">
                <li className="mb-staticSm">
                  <FancyLink
                    href={URL_LIBRARY.WELLCOME_MAIN_SITE.CONTACT_US}
                    icon={{
                      name: 'iconContentLink',
                      className: 'c-contact__link-icon -mb-staticXs',
                      beforeText: true,
                    }}
                  >
                    Send us a message
                  </FancyLink>
                </li>
                <li>
                  <FancyLink
                    href={URL_LIBRARY.WELLCOME_MAIN_SITE.TELEPHONE}
                    icon={{
                      name: 'iconSocialPhone',
                      className: 'c-contact__link-icon -mb-staticXs',
                      beforeText: true,
                    }}
                  >
                    +44 20 7611 5757
                  </FancyLink>
                </li>
              </ul>
            </div>
          </div>

          <hr className="flex border-t-1 border-amber-20 border-solid h-0 my-staticMd sm:my-staticLg lg:my-staticXl" />
        </section>
        <section className="flex flex-wrap items-start">
          <WellcomeBrandLogo
            className="flex-none w-staticXxl h-staticXxl lg:w-staticXxxl lg:h-staticXxxl has-current-colour"
            labelledBy="site-footer-logo"
            title="Wellcome"
          />

          <ul className="flex-none basis-full lg:basis-auto order-2 lg:order-1 list-none ml-0 lg:ml-staticXl mt-staticLg  mb-staticMd sm:mb-0 pl-0 text-body-sm w-full lg:w-auto">
            <li className="mr-staticMd md:mr-staticXl sm:inline-block">
              <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.HOME}>Main Wellcome website</FancyLink>
            </li>
            <li className="mr-staticMd md:mr-staticXl sm:inline-block">
              <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.PRIVACY_AND_TERMS}>Privacy and terms</FancyLink>
            </li>
            {cookieControl && (
              <li className="mr-staticMd md:mr-staticXl sm:inline-block">
                <Button type="button" variant="inline" onClick={handleShowCivic}>
                  Manage cookies
                </Button>
              </li>
            )}
            <li className="mr-staticMd md:mr-staticXl sm:inline-block">
              <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.MODERN_SLAVERY_STATEMENT}>
                Modern slavery statement
              </FancyLink>
            </li>
            <li className="mr-staticMd md:mr-staticXl sm:inline-block">
              <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.ACCESSIBILITY}>Accessibility</FancyLink>
            </li>
            <li className="mr-staticMd md:mr-staticXl sm:inline-block">
              <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.CONTACT_US}>Contact us</FancyLink>
            </li>
          </ul>

          <span className="flex-none order-1 lg:order-2 ml-auto mt-staticXs lg:mt-staticLg text-body-sm">
            <FancyLink
              className="mr-0"
              icon={{
                name: 'iconDirectionalChevronUp',
                beforeText: true,
              }}
              href="#top"
            >
              Back to top
            </FancyLink>
          </span>
        </section>
      </Container>
    </footer>
  );
};
export default ExtendedFooter;
