import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

const COOKIE_NAME = 'hide-global-banner';

const COOKIE_EXPIRY = 1 * 24 * 60 * 60 * 1000; // 24 hours

export const useShowGlobalBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    // Hide banner if user dismmisses
    if (cookies[COOKIE_NAME]) setIsVisible(false);
    else setIsVisible(true);
  }, []);

  const hideBanner = () => {
    setCookie(COOKIE_NAME, true, {
      path: '/',
      expires: new Date(Date.now() + COOKIE_EXPIRY),
    });

    setIsVisible(false);
  };

  return { isVisible, hideBanner };
};

export default useShowGlobalBanner;
