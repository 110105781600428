import { nanoid } from 'nanoid';
import { createContext, ReactNode, useReducer, useState } from 'react';

import { NotificationVariants } from '../Notifications/shared-notification-configuration';

export type addToastParameters = {
  autoHide?: boolean;
  notificationText: string;
  notificationTitle: string;
  variant: keyof typeof NotificationVariants;
};

type ProviderProps = {
  children: ReactNode;
};

type notificationsAddData = {
  autoHide?: boolean;
  id: string;
  notificationText: string;
  notificationTitle: string;
  variant: keyof typeof NotificationVariants;
};

type notificationsDeleteData = {
  id: string;
};

type notificationsReducerAction =
  | { type: 'ADD_NOTIFICATION'; data: notificationsAddData }
  | { type: 'DELETE_NOTIFICATION'; data: notificationsDeleteData };

type toastAutohideTimerItem = {
  id: string;
  setTimeoutItem: ReturnType<typeof setTimeout>;
};

export type notificationsListItem = {
  autoHide?: boolean;
  id: string;
  notificationText: string;
  notificationTitle: string;
  variant: keyof typeof NotificationVariants;
};

export const NotificationsContext = createContext(null);

export const NotificationsContextProvider = (props: ProviderProps) => {
  const [autoHideTimers, setAutoHideTimers] = useState<toastAutohideTimerItem[]>([]);

  const notificationsList: notificationsListItem[] = [];

  const [notifications, dispatch] = useReducer(notificationsReducer, notificationsList);

  const addToast = ({ autoHide = false, notificationText, notificationTitle, variant }: addToastParameters) => {
    const newId = nanoid();
    dispatch({
      type: 'ADD_NOTIFICATION',
      data: {
        autoHide,
        id: newId,
        notificationText,
        notificationTitle,
        variant,
      },
    });
    if (autoHide) {
      startAutohideTimer(newId);
    }
  };

  const deleteToast = (toastId: string) => {
    dispatch({
      type: 'DELETE_NOTIFICATION',
      data: {
        id: toastId,
      },
    });
  };

  const startAutohideTimer = (toastId: string) => {
    const toastHideTimer = setTimeout(() => {
      dispatch({
        type: 'DELETE_NOTIFICATION',
        data: {
          id: toastId,
        },
      });

      setAutoHideTimers((currentTimers) => currentTimers.filter((timerItem) => timerItem.id !== toastId));
    }, 5000);

    setAutoHideTimers((arr) => [...arr, { id: toastId, setTimeoutItem: toastHideTimer }]);
  };

  const stopAutohideTimer = (toastId: string) => {
    const timer = autoHideTimers?.find((toast) => toast.id === toastId);

    if (timer) {
      clearTimeout(timer.setTimeoutItem);

      setAutoHideTimers((currentTimers) => currentTimers.filter((timerItem) => timerItem.id !== toastId));
    }
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, dispatch, addToast, deleteToast, startAutohideTimer, stopAutohideTimer }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

const notificationsReducer = (
  notifications: notificationsListItem[],
  action: notificationsReducerAction
): notificationsListItem[] => {
  switch (action.type) {
    case 'ADD_NOTIFICATION': {
      return action.data.variant.length
        ? [
            {
              autoHide: action.data.autoHide,
              id: action.data.id,
              notificationText: action.data.notificationText || '',
              notificationTitle: action.data.notificationTitle || '',
              variant: action.data.variant,
            },
            ...notifications,
          ]
        : notifications;
    }
    case 'DELETE_NOTIFICATION': {
      return notifications.filter((x) => x.id !== action.data.id);
    }
    default: {
      return notifications;
    }
  }
};
