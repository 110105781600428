/**
 * This file contains the behaviours for the GlobalNav component. It exists because
 * we are currently maintaining two versions of the GlobalNav component - one for
 * the deprecated layout and one for the new layout.
 *
 * @see {@link https://github.com/wellcometrust/funding-platform-frontend/pull/932#discussion_r1184973139}
 */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import usePermissions from './usePermissions';
import useStatus from './useStatus';

import getGlobalNavLinks from 'components/GlobalNav/GlobalNavItems';
import MenuContext from 'components/MenuContext';
import ViewportContext from 'components/ViewportContext';

const useGlobalNavBehaviour = () => {
  const { isMenuLockedOpen, setIsMenuLockedOpen, isMenuOverlayVisible, setIsMenuOverlayVisible, setIsMenuVisible } =
    useContext(MenuContext);
  const { isLarge, isTouchDevice } = useContext(ViewportContext);
  const timer = useRef<ReturnType<typeof setInterval>>(null);

  // Button refs are used for shifting focus on global nav toggle
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const openButtonRef = useRef<HTMLButtonElement>(null);

  const [isTouchEvent, setIsTouchEvent] = useState(false);
  const [isAnimatingShut, setIsAnimatingShut] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const [showMenuLock, setShowMenuLock] = useState(false);
  const [showMenuUnlock, setShowMenuUnlock] = useState(false);
  const [showLockToolTip, setShowLockToolTip] = useState(false);
  const [showUnlockToolTip, setShowUnlockToolTip] = useState(false);

  const [maybeCloseMenuOverlay, setMaybeCloseMenuOverlay] = useState(false);
  const [showLockNavIndicator, setShowLockNavIndicator] = useState(false);

  const { user } = useStatus();
  const { permissions } = usePermissions();

  const globalNavLinks = getGlobalNavLinks(permissions, user?.hasExpertReviews, user?.hasCommittees);

  // Methods to show and hide menu
  const showOverlayMenu = () => {
    /*
     * Check if the menu is animating shut - if not,
     * continue to show the menu.
     */
    if (!isAnimatingShut) {
      setShowLockNavIndicator(true);
      setMaybeCloseMenuOverlay(false);
      setIsMenuOverlayVisible(true);
      clearTimeout(timer.current);
    }
  };

  const hideOverlayMenu = () => {
    setShowLockNavIndicator(false);
    setShowLockToolTip(false);
    setShowUnlockToolTip(false);

    // Use timeout to stop accidental mouse out from triggering menu close.
    timer.current = setTimeout(() => setMaybeCloseMenuOverlay(true), 300);
  };

  const toggleNavLock = () => {
    setIsAnimatingShut(true);
    if (isLarge) {
      setIsMenuLockedOpen(!isMenuLockedOpen);
      setShowUnlockToolTip(false);
      setMaybeCloseMenuOverlay(true);
    } else if (!isTouchEvent) {
      setIsMenuOverlayVisible(!isMenuOverlayVisible);
    }
    /*
     * Stops accidental mouseover of tooltip when closing menu
     * from accidentally blocking menu close.
     */
    timer.current = setTimeout(() => setIsAnimatingShut(false), 300);

    /**
     * Moves focus from open button to close button and vice versa.
     * Timeout ensures this process happens after attributes and styles are
     * set correctly to allow the relevant button to receive focus.
     */
    setTimeout(() => {
      if (isMenuLockedOpen) {
        openButtonRef?.current?.focus();
      } else {
        closeButtonRef?.current?.focus();
      }
    }, 100);
  };

  // Handle tooltip for lock menu trigger
  const handleShowToolTip = () => {
    setShowLockToolTip(true);
  };

  const handleHideToolTip = () => {
    setShowLockToolTip(false);
  };

  // Handler to lock and unlock menu
  const handleHamburgerClick = (e) => {
    e.preventDefault();
    toggleNavLock();
    setShowLockNavIndicator(false);
    setShowLockToolTip(false);
    setShowUnlockToolTip(false);
    setIsTouchEvent(false);
  };

  /*
   * Handlers for mouse enter and leave of the overlay menu.
   * These trigger menu to be hidden when leaving the menu
   * and stop it from disappearing when the user mouses out of
   * the hamburger and in to the menu.
   */
  const handleMouseEnterMenu = (e) => {
    e.preventDefault();
    showOverlayMenu();
    setShowMenuUnlock(true);
  };

  const handleMouseOverMenu = (e) => {
    e.preventDefault();
    setShowMenuUnlock(true);
  };

  const handleMouseLeaveMenu = (e) => {
    e.preventDefault();
    hideOverlayMenu();
    setShowMenuUnlock(false);
  };

  /*
   * Handles overlay menu show hide interaction on touch devices.
   * We block set state to allow us to not respond to mouse enter
   * and leave if the menu is triggered on a touch device.
   */
  const handleTouchStart = () => {
    setIsTouchEvent(true);
    if (!isMenuOverlayVisible) {
      showOverlayMenu();
    } else {
      setMaybeCloseMenuOverlay(true);
    }
  };

  // Mouse enter/leave handlers for hamburger menu
  const handleHamburgerMouseEnter = (e) => {
    e.preventDefault();
    if (!isTouchEvent) {
      showOverlayMenu();
      handleShowToolTip();
      setShowMenuLock(true);
    }
  };

  const handleHamburgerMouseLeave = (e) => {
    e.preventDefault();
    if (!isTouchEvent) {
      hideOverlayMenu();
      handleHideToolTip();
      setShowMenuLock(false);
    }
  };

  // Handle tooltip for unlock menu trigger
  const handleShowUnlockToolTip = (e) => {
    e.preventDefault();
    setShowUnlockToolTip(true);
  };

  const handleHideUnlockToolTip = (e) => {
    e.preventDefault();
    setShowUnlockToolTip(false);
  };

  // Handle tooltip for unlock menu trigger
  const handleShowLockToolTip = (e: React.FocusEvent) => {
    e.preventDefault();
    setShowLockNavIndicator(true);
    setShowLockToolTip(true);
  };

  const handleHideLockToolTip = (e: React.FocusEvent) => {
    e.preventDefault();
    setShowLockToolTip(false);
  };

  // Hide overlay menu when escape key pressed
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShowLockToolTip(false);
      setShowUnlockToolTip(false);
      hideOverlayMenu();

      if (!isLarge) {
        openButtonRef?.current?.focus();
      }
    }
  }, []);

  /*
   * Hide or show overlay menu after initial mount.
   * This actually hides the menu - it's triggered
   * via a timeOut to stop brief mouse outs hiding the menu.
   */
  useEffect(() => {
    if (hasMounted) {
      setIsMenuOverlayVisible(!maybeCloseMenuOverlay);
    }
  }, [maybeCloseMenuOverlay]);

  // Bind listener for escape key, and flag component has mounted.
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    setHasMounted(true);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (!isLarge) {
      setShowUnlockToolTip(false);
      setIsMenuOverlayVisible(false);
    }
  }, [isLarge]);

  useEffect(() => {
    setIsMenuVisible(true);
    return () => setIsMenuVisible(false);
  }, []);

  return {
    handleHamburgerClick,
    handleHamburgerMouseEnter,
    handleHamburgerMouseLeave,
    handleMouseEnterMenu,
    handleMouseOverMenu,
    handleMouseLeaveMenu,
    handleTouchStart,
    handleShowUnlockToolTip,
    handleHideUnlockToolTip,

    handleShowLockToolTip,
    handleHideLockToolTip,

    showMenuUnlock,
    showLockToolTip,
    showUnlockToolTip,
    showLockNavIndicator,
    isMenuLockedOpen,
    isMenuOverlayVisible,
    isAnimatingShut,
    permissions,
    user,
    globalNavLinks,

    isLarge,
    isTouchDevice,

    isTouchEvent,
    setIsTouchEvent,

    setIsMenuLockedOpen,
    setIsMenuOverlayVisible,
    setIsMenuVisible,
    setIsAnimatingShut,
    setMaybeCloseMenuOverlay,
    setShowMenuUnlock,
    setShowLockToolTip,
    setShowUnlockToolTip,
    setShowLockNavIndicator,
    setHasMounted,

    maybeCloseMenuOverlay,
    showMenuLock,

    closeButtonRef,
    openButtonRef,
  };
};

export default useGlobalNavBehaviour;
