import cx from 'classnames';
import { Children } from 'react';

type FancyLinkListProps = {
  headerText?: string;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
};

export const FancyLinkList = (props: FancyLinkListProps) => (
  <div className={cx({ [props.className]: props.className })}>
    {props.headerText && <h3 className="font-medium text-heading-md mb-md">{props.headerText}</h3>}
    {props.children && (
      <ul className="list-none m-0 p-0" data-cy="fancyLinkList">
        {Children.map(props.children, (child) => child && <li className="mt-responsiveSm first:mt-0">{child}</li>)}
      </ul>
    )}
  </div>
);

export default FancyLinkList;
