import cx from 'classnames';
import { ElementType, ReactNode } from 'react';

type FancyHeadingProps = {
  children: ReactNode;
  className?: string;
  element?: ElementType;
  fontFamily?: 'primary' | 'secondary';
  fontSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  id?: string;
  hasUnderline?: boolean;
};

export const FancyHeading = ({
  children,
  className,
  element: Element = 'h2',
  fontFamily = 'primary',
  fontSize = 'xl',
  id,
  hasUnderline = true,
}: FancyHeadingProps) => {
  /**
   * An enum to return the exact classname we need for the element.
   *
   * We do this because Tailwind cannot interpret string interpolation, and therefore
   * will not include the classname in the compiled CSS.
   *
   * @see {@link https://tailwindcss.com/docs/content-configuration#dynamic-class-names}
   */
  const fontSizeClassName = {
    xs: 'text-heading-xs',
    sm: 'text-heading-sm',
    md: 'text-heading-md',
    lg: 'text-heading-lg',
    xl: 'text-heading-xl',
    xxl: 'text-heading-xxl',
  }[fontSize];

  return (
    <Element
      className={cx(`${fontSizeClassName} font-${fontFamily}`, {
        'font-medium': fontFamily === 'primary',
        [className]: className,
        "after:bg-amber-30 after:mt-md after:w-8 after:h-staticXxs after:block after:content-['']": hasUnderline,
        'mb-responsiveSm': fontSize !== 'xxl',
        'mb-responsiveMd': fontSize === 'xxl',
      })}
      id={id}
    >
      {children}
    </Element>
  );
};

export default FancyHeading;
