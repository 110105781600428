import { useRouter } from 'next/router';

export const useExpertReviewParams = () => {
  const router = useRouter();

  return {
    expertReviewId: (router.query.expertReviewId as string) || undefined,
  };
};

export default useExpertReviewParams;
