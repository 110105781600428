import { createContext, Dispatch, SetStateAction } from 'react';

import { ApiResponse } from 'utils/api/types';

export type ContextProps = {
  hasSaved: boolean;
  setHasSaved?: Dispatch<SetStateAction<boolean>>;
  savingCounter?: number;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  lastSavedAtTime?: string;
  setLastSavedAtTime: Dispatch<SetStateAction<string>>;
  hasSavingError: boolean;
  setHasSavingError: Dispatch<SetStateAction<boolean>>;
  shouldAutoSave?: boolean;
  formId: string;
  rootId: string;
  onFormFieldValueChange?: () => void;
  customSaveFunction?: (formDataRowToSave: any, allFormData: any, allFormErrors: any) => Promise<ApiResponse>;
};

export const FormAutoSaveContext = createContext<ContextProps>({
  hasSaved: false,
  savingCounter: 0,
  lastSavedAtTime: null,
  hasSavingError: false,
  setIsSaving: undefined,
  setHasSaved: undefined,
  setLastSavedAtTime: undefined,
  shouldAutoSave: false,
  setHasSavingError: undefined,
  formId: undefined,
  rootId: undefined,
  onFormFieldValueChange: undefined,
  customSaveFunction: undefined,
});

export default FormAutoSaveContext;
