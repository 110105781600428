import { ErrorBoundary } from '@sentry/nextjs';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { SWRConfig } from 'swr';

import 'styles/app.css';

import { AnalyticsContextProvider } from '../components/AnalyticsContext';

import { SiteDown } from './site-down';

import CookiesBanner from 'components/CookiesBanner';
import { MenuContextProvider } from 'components/MenuContext';
import ToastList from 'components/Notifications/ToastList';
import { NotificationsContextProvider } from 'components/NotificationsContext';
import Session from 'components/Session';
import SkipLink from 'components/SkipLink';
import { ViewportContextProvider } from 'components/ViewportContext';

import { useInitAnalytics } from 'hooks/useInitAnalytics';
import usePreviousPagePath from 'hooks/usePreviousPagePath';
import useStatus from 'hooks/useStatus';
import ErrorPage from 'pages/500';
import reportAccessibility from 'utils/reportAccessibility';

const LoadAnalytics = () => {
  useInitAnalytics();
  return null;
};

const useApplyBodyClasses = () => {
  const router = useRouter();
  useEffect(() => {
    if (navigator.appVersion.indexOf('Win') !== -1) {
      document.documentElement.classList.add('is-windows');
    }
    router.events.on('routeChangeStart', () => {
      document.documentElement.classList.add('normal-scroll');
    });
    router.events.on('routeChangeComplete', () => {
      document.documentElement.classList.remove('normal-scroll');
    });
  }, []);
};

const LoadAccessibilityChecks = () => {
  reportAccessibility(React);
  return null;
};

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  useApplyBodyClasses();
  const { storePathValues } = usePreviousPagePath();
  useEffect(() => storePathValues, [router.asPath]);

  const { data, isLoading } = useStatus();

  if (isLoading) return null;

  const isSiteDown = data?.status === 'DOWN_PLANNED' || data?.status === 'DOWN_UNPLANNED';

  return (
    <ViewportContextProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      <ErrorBoundary fallback={<ErrorPage />} key={router.asPath}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
          }}
        >
          {isSiteDown ? (
            <SiteDown status={data?.status} plannedDowntime={data?.plannedDowntime} />
          ) : (
            <AnalyticsContextProvider>
              <CookiesProvider>
                <MenuContextProvider>
                  <NotificationsContextProvider>
                    <Session>
                      <SkipLink
                        className="focus:fixed left-2/4 -translate-x-1/2 focus:rounded-t-none"
                        href="#main-content-target"
                      >
                        Skip to main content
                      </SkipLink>
                      <LoadAnalytics />
                      <LoadAccessibilityChecks />
                      <ToastList />
                      <Component key={router.asPath} {...pageProps} />
                      <CookiesBanner />
                    </Session>
                  </NotificationsContextProvider>
                </MenuContextProvider>
              </CookiesProvider>
            </AnalyticsContextProvider>
          )}
        </SWRConfig>
      </ErrorBoundary>
    </ViewportContextProvider>
  );
};

export default App;
