import { Icon } from '@wellcometrust/design-system';
import type { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import cx from 'classnames';
import Link from 'next/link';
import { FocusEventHandler, forwardRef, MouseEventHandler, ReactNode, Fragment, AriaAttributes } from 'react';

import { Spinner } from 'components/Spinner/Spinner';

import isInternalHref from 'utils/is-internal-href';

export type ButtonProps = Pick<AriaAttributes, 'aria-controls' | 'aria-label'> & {
  autoFocus?: boolean;
  className?: string;
  children: ReactNode;
  href?: string;
  hasSpinner?: boolean;
  icon?: {
    name: IconProps['name'];
    className?: string;
    beforeText?: boolean;
  };
  id?: string;
  isDisabled?: boolean;
  onBlur?: FocusEventHandler;
  onClick?: MouseEventHandler;
  onFocus?: FocusEventHandler;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  metaText?: string;
  spanText?: string;
  role?: string;
  target?: HTMLAnchorElement['target'];
  textClassName?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'inline' | 'context';
  'data-cy'?: string;
};

/**
 * Custom component to render a <button /> or <a /> element with custom
 * styles/props.
 *
 * Wraps the Button component from the @wellcometrust/design-system package:
 * we can't use the Button component directly because we want to make use of
 * client-side routing using the next/link package.
 */
export const Button = forwardRef<HTMLAnchorElement & HTMLButtonElement, ButtonProps>((props, ref) => {
  const Wrapper = isInternalHref(props.href) ? Link : Fragment;
  const Component = props.href ? 'a' : 'button';

  const hasIcon = props.icon;
  const variant = props.isDisabled && !props.hasSpinner ? 'disabled' : props.variant || 'primary';

  const classNames = {
    primary: ['c-button--primary min-w-thumb min-h-thumb', 'c-button__text--primary'],
    secondary: ['c-button--secondary min-w-thumb min-h-thumb', 'c-button__text--secondary'],
    tertiary: ['c-button--tertiary min-w-thumb min-h-thumb', 'c-button__text--tertiary'],
    success: ['c-button--success min-w-thumb min-h-thumb', 'c-button__text--success'],
    disabled: ['c-button--disabled min-w-thumb min-h-thumb', 'c-button__text--disabled'],
    context: ['c-button--context min-w-thumb min-h-thumb', 'c-button__text--context'],

    /**
     * @todo: remove this variant once we have the time to think about
     * how to share styles between the FancyLink and Button components.
     */
    inline: ['c-fancy-link px-0 text-left', 'c-fancy-link__text'],
  };

  return (
    <>
      <Wrapper {...(Wrapper === Link ? { href: props.href, legacyBehavior: true } : undefined)}>
        <Component
          ref={ref}
          aria-controls={props['aria-controls']}
          aria-label={props['aria-label']}
          className={cx('c-button', classNames[variant][0], props.className)}
          disabled={props.isDisabled}
          href={props.href}
          id={props.id}
          role={props.role}
          type={props.type}
          onBlur={props.onBlur}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          target={props.target}
          aria-disabled={props.isDisabled}
          data-cy={props['data-cy']}
        >
          {props.hasSpinner && <Spinner size="sm" className="w-staticLg" />}
          {hasIcon && props.icon.beforeText && <Icon name={props.icon.name} className={props.icon.className} />}
          {props.children && (
            <span>
              <span className={cx('c-button__text', props.textClassName, classNames[variant][1])}>
                {props.children}
              </span>
              {props.metaText && <span className="text-grey-60 text-body-xs ml-staticXs">{props.metaText}</span>}
              {props.spanText && <span>{props.spanText}</span>}
            </span>
          )}
          {hasIcon && !props.icon.beforeText && <Icon name={props.icon.name} className={props.icon.className} />}
        </Component>
      </Wrapper>
    </>
  );
});

Button.displayName = 'Button';

export default Button;
