import { Icon } from '@wellcometrust/design-system';
import cx from 'classnames';
import { ReactNode, useState } from 'react';

import Button from '../Button';

import useStorage from 'hooks/useStorage';

type OnboardingTooltipProps = {
  name: string;
  title: string;
  hasCta?: boolean;
  arrowPosition?: 'top' | 'bottom';
  className?: string;
  children: ReactNode;
};

const OnboardingTooltip = (props: OnboardingTooltipProps) => {
  const { getItem, setItem } = useStorage();

  const [hasOnboarded, setHasOnboarded] = useState(Boolean(getItem(`${props.name}-onboarded`, 'local')));

  const handleClose = () => {
    setItem(`${props.name}-onboarded`, 'true', 'local');
    setHasOnboarded(true);
  };

  if (hasOnboarded) return;

  return (
    <div
      className={cx(
        `absolute -top-[25px] -translate-y-full bg-cyan-10 rounded-md w-[280px] sm:w-[350px] p-responsiveSm shadow`,
        {
          "after:content-[''] after:w-[24px] after:h-[24px] after:block after:absolute after:left-1/4 after:-translate-x-1/2 after:translate-y-1/2 after:bottom-0 after:rotate-45 after:bg-cyan-10":
            props.arrowPosition === 'bottom',
          "after:content-[''] after:w-[24px] after:h-[24px] after:block after:absolute after:left-1/4 after:-translate-x-1/2 after:-translate-y-1/2 after:top-0 after:rotate-45 after:bg-cyan-10":
            props.arrowPosition === 'top',
          [props.className]: props.className,
        }
      )}
    >
      <h3 className="font-bold mb-staticXs">{props.title}</h3>
      <p className="leading-6 mb-staticMd">{props.children}</p>
      {props.hasCta ? (
        <Button variant="secondary" onClick={handleClose}>
          Got it
        </Button>
      ) : (
        <Button
          variant="tertiary"
          className="bg-transparent w-[15px] m-auto absolute right-staticSm top-staticXs hover:bg-transparent"
          onClick={handleClose}
          data-cy="onboarding-tooltip-close"
        >
          <Icon name="iconActionCross" />
        </Button>
      )}
    </div>
  );
};

export default OnboardingTooltip;
