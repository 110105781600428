import { useRouter } from 'next/router';

export const useApplicationParams = () => {
  const router = useRouter();

  return {
    applicationId: (router.query.applicationId as string) || undefined,
    formId: (router.query.formId as string) || undefined,
    sectionId: (router.query.sectionId as string) || undefined,
  };
};

export default useApplicationParams;
