export const WellcomeLogo = ({
  className,
  labelledBy = 'site-header-logo',
  title = 'Wellcome',
  viewBox = '0 0 50 50',
}: {
  className?: string;
  labelledBy?: string;
  title?: string;
  viewBox?: string;
}) => (
  <svg aria-labelledby={labelledBy} className={className} preserveAspectRatio="xMinYMid" role="img" viewBox={viewBox}>
    <title id={labelledBy}>{title}</title>
    <path
      d="M50,50H0V0H50ZM34.83,13.57l-3.34,12.7c-1.28-4.84-2.44-9.22-2.64-9.94a3.42,3.42,0,0,0-3.58-2.76H21.85L18.5,26.28,15.16,13.57l-7.5.87S11.78,30.13,12.53,33a4.48,4.48,0,0,0,3.29,3.56c1.9.51,5.82,1.68,5.82,1.68L25,25.47c1,3.86,1.8,6.7,2.1,7.81a4.54,4.54,0,0,0,3.46,3.29l5.53,1.64,6.26-23.82Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default WellcomeLogo;
