import Form from '@rjsf/core';
import { ErrorSchema } from '@rjsf/utils';
import { createContext, Dispatch, MutableRefObject, SetStateAction } from 'react';

import { FileMetadata } from 'hooks/useFormFieldFile';
import { ApiError } from 'utils/api/types';

export type FormContextProps = {
  customFieldErrors: ErrorSchema;
  setCustomFieldErrors: Dispatch<SetStateAction<object>>;
  focus?: string;
  setFocus: Dispatch<SetStateAction<string>>;
  formIsDirty: boolean;
  setFormIsDirty: Dispatch<SetStateAction<boolean>>;
  initialFormData?: object;
  setInitialFormData?: Dispatch<SetStateAction<object>>;
  isTestForm?: boolean;
  setIsTestForm: Dispatch<SetStateAction<boolean>>;
  ref: MutableRefObject<Form<object>>;
  setRef: Dispatch<SetStateAction<MutableRefObject<Form<object>>>>;
  processFile: (file: File) => Promise<{ success: boolean; fileMetadata: FileMetadata; errors: ApiError[] }>;
  removeFile: () => Promise<{ success: boolean; errors: ApiError[] }>;
};

export const FormContext = createContext<FormContextProps>({
  customFieldErrors: undefined,
  setCustomFieldErrors: undefined,
  focus: null,
  setFocus: undefined,
  formIsDirty: false,
  setFormIsDirty: undefined,
  initialFormData: undefined,
  setInitialFormData: undefined,
  isTestForm: false,
  setIsTestForm: undefined,
  ref: null,
  setRef: undefined,
  processFile: undefined,
  removeFile: undefined,
});

export default FormContext;
