import cx from 'classnames';
import { useContext } from 'react';

import Breadcrumbs from '../Breadcrumbs';
import MenuContext from '../MenuContext';
import OnboardingTooltip from '../OnboardingTooltip';
import Spinner from '../Spinner';
import Tooltip from '../Tooltip';
import UserMenu from '../UserMenu';

import Container from './Container';

import type { BreadcrumbsProps } from 'components/Breadcrumbs/Breadcrumbs';
import FormAutoSaveContext from 'components/FormAutoSaveContext';
import GlobalBanner from 'components/Notifications/GlobalBanner';

import useStatus from 'hooks/useStatus';

type MenuBarProps = {
  crumbs?: BreadcrumbsProps['crumbs'];
  pageTitle?: string;
  suppressOnboarding?: boolean;
  pageType?: string;
};

export const MenuBar = (props: MenuBarProps) => {
  const { isMenuLockedOpen } = useContext(MenuContext);
  const { user } = useStatus();
  const { hasSaved, savingCounter, lastSavedAtTime } = useContext(FormAutoSaveContext);
  const { data: siteStatus, isLoading } = useStatus();
  const { pageType = 'application' } = props;

  if (isLoading) return null;

  return (
    <>
      <div className="bg-white border-b-1 border-grey-10 sticky top-0 z-[40] h-menubar u-scroll-lock-compensation">
        <Container maxWidth="full">
          <div
            className={cx(
              'flex items-center justify-between transition-spacing duration-300 py-staticMd ml-[48px] md:ml-0 md:pl-responsiveLg lg:pl-0',
              {
                'lg:!pl-responsiveLg': !isMenuLockedOpen,
              }
            )}
          >
            <div className="flex items-center">
              {(props.crumbs || props.pageTitle) && <Breadcrumbs crumbs={props.crumbs} pageTitle={props.pageTitle} />}
              {(savingCounter > 0 || hasSaved) && (
                <div className="ml-lg flex-shrink-0">
                  {savingCounter > 0 && <Spinner>Saving...</Spinner>}
                  {savingCounter < 1 && hasSaved && (
                    <div className="relative flex items-center">
                      <Tooltip className="cursor-help" id="last-saved-at-tooltip" label="Saved">
                        Last saved at {lastSavedAtTime}
                      </Tooltip>
                      {!props.suppressOnboarding && (
                        <OnboardingTooltip
                          className="-bottom-[35px] !top-auto !translate-y-full -translate-x-1/2 xs:-translate-x-1/4 after:left-1/2 after:xs:left-1/4 after:!translate-x-1/2"
                          name="autosave"
                          title="Automatically saving your work"
                          hasCta
                          arrowPosition="top"
                        >
                          Your {pageType} is always saving in the background so you don’t have to worry about losing
                          your work.
                        </OnboardingTooltip>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            {user && <UserMenu user={user} />}
          </div>
        </Container>
      </div>
      {siteStatus?.plannedDowntime && <GlobalBanner plannedDownTime={siteStatus.plannedDowntime} />}
    </>
  );
};

export default MenuBar;
