import { createContext, ReactNode, useEffect, useState } from 'react';

import useStorage from '../../hooks/useStorage';

const defaultState = {
  isMenuLockedOpen: true,
  setIsMenuLockedOpen: () => {},
  isMenuOverlayVisible: false,
  setIsMenuOverlayVisible: () => {},
  isMenuVisible: false,
  setIsMenuVisible: () => {},
  isFormNavVisible: false,
  setIsFormNavVisible: () => {},
};

type MenuContextProps = {
  isMenuLockedOpen: boolean;
  setIsMenuLockedOpen: (bool: boolean) => void;
  isMenuOverlayVisible: boolean;
  setIsMenuOverlayVisible: (bool: boolean) => void;
  isMenuVisible: boolean;
  setIsMenuVisible: (bool: boolean) => void;
  isFormNavVisible: boolean;
  setIsFormNavVisible: (bool: boolean) => void;
};

type ProviderProps = {
  children: ReactNode;
};

export const MenuContext = createContext<MenuContextProps>(defaultState);

export const MenuContextProvider = ({ children }: ProviderProps) => {
  const { getItem, setItem } = useStorage();
  const [hasLoadedSessionState, setHasLoadedSessionState] = useState(false);

  const [state, setState] = useState<MenuContextProps>({
    isMenuLockedOpen: true,
    setIsMenuLockedOpen: (bool) => setState((prevState) => ({ ...prevState, isMenuLockedOpen: bool })),
    isMenuOverlayVisible: false,
    setIsMenuOverlayVisible: (bool) => setState((prevState) => ({ ...prevState, isMenuOverlayVisible: bool })),
    isMenuVisible: false,
    setIsMenuVisible: (bool) => setState((prevState) => ({ ...prevState, isMenuVisible: bool })),
    isFormNavVisible: false,
    setIsFormNavVisible: (bool) => setState((prevState) => ({ ...prevState, isFormNavVisible: bool })),
  });

  useEffect(() => {
    if (hasLoadedSessionState) {
      setItem('menu-locked', String(state.isMenuLockedOpen), 'session');
    }
  }, [state.isMenuLockedOpen]);

  useEffect(() => {
    const menuStatus = getItem('menu-locked', 'session');
    setHasLoadedSessionState(true);
    state.setIsMenuLockedOpen(menuStatus === 'true' || menuStatus === undefined);
  }, []);

  return <MenuContext.Provider value={state}>{children}</MenuContext.Provider>;
};

MenuContext.displayName = 'MenuContext';

export default MenuContext;
