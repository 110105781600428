import { Icon } from '@wellcometrust/design-system';
import { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import cx from 'classnames';

import Button from '../Button';

import { NotificationProps, NotificationVariants } from './shared-notification-configuration';

type PageLevelBannerProps = NotificationProps & {
  onClose?: () => void;
};

const PageLevelBanner = (props: PageLevelBannerProps) => (
  <div
    className={cx(
      'flex items-start flex-wrap py-staticMd px-staticMd sm:px-staticXl lg:px-staticXxl md:items-center',
      NotificationVariants[props.notificationVariant].bannerBackground,
      props.className
    )}
    role="alert"
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    data-cy="page-level-banner"
  >
    <Icon
      name={
        props?.customIcon?.name
          ? props.customIcon.name
          : (NotificationVariants[props.notificationVariant].defaultIcon as IconProps['name'])
      }
      className={cx(
        'flex-none mr-staticXs',
        NotificationVariants[props.notificationVariant].darkTintedText,
        props?.customIcon?.className
      )}
    />
    <div className="flex flex-1 flex-wrap">
      <div
        className={cx('flex-1 text-heading-sm text-grey-80 items-center', {
          [props.title?.className]: props.title?.className,
          'mr-staticMd': props.onClose || props.action,
        })}
      >
        {props.title && (
          <strong className={cx('font-bold', NotificationVariants[props.notificationVariant].darkTintedText)}>
            {props.title.text}
          </strong>
        )}
        {props.children && <> {props.children}</>}
      </div>

      {props.action && (
        <div
          className={cx(
            'flex flex-none flex-col justify-items-center mt-staticSm sm:mt-0 sm:ml-auto basis-full sm:basis-auto',
            { 'pr-responsiveMd': props.onClose }
          )}
        >
          <Button
            className={cx('min-h-[0px] mb-[-4px] inline text-left', props.action?.className)}
            href={props.action?.href}
            onClick={props.action?.onClick}
            type="button"
            variant="inline"
          >
            {props.action?.text}
          </Button>
        </div>
      )}
    </div>

    {props.onClose && (
      <div className="flex flex-none flex-col ml-auto -mr-staticXs sm:-mr-staticLg">
        <button
          onClick={props.onClose}
          type="button"
          className={cx(
            'min-h-thumb min-w-thumb flex flex-col justify-items-center -my-staticXs transition-color duration-300 ease-in-out',
            NotificationVariants[props.notificationVariant].closeHover
          )}
        >
          <Icon name="iconActionCross" className="m-auto" />
          <span className="sr-only">Close banner</span>
        </button>
      </div>
    )}
  </div>
);

export default PageLevelBanner;
