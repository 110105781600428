import useSectionData from './useSectionData';
import useSubmissionStatus from './useSubmissionStatus';

import useApplicationOverview from 'hooks/useApplicationOverview';
import useApplicationParams from 'hooks/useApplicationParams';
import usePermissions from 'hooks/usePermissions';

/**
 * Returns the status of the section based on the user's role, which can be used in the UI to
 * present the status as we would like the current user to consume it.
 *
 * We have multiple status fields (applicantStatus, researchOfficeStatus) for each section in a form,
 * as well as another status (submissionStatus) which holds the overall status of the application.
 *
 * In certain parts of the UI, we want to show a different value to the current user based on:
 *
 * - the user's role on the application
 * - the value of the submissionStatus field
 * - the value of the applicantStatus and researchOfficeStatus fields
 */
export const useRoleBasedSectionStatus = (id: string) => {
  const { formId, applicationId } = useApplicationParams();
  const { isLoading } = useApplicationOverview(`${formId}/${applicationId}`);
  const { permissions } = usePermissions();
  const { section } = useSectionData(id);
  const { isWithResearchOffice, isWithWellcome } = useSubmissionStatus(formId, applicationId);

  const getSectionStatus = () => {
    /**
     * Wrap in try/catch to prevent errors when the data is not yet loaded
     */
    try {
      if (permissions === 'OFFICE' && (isWithResearchOffice || isWithWellcome)) {
        /**
         * If the section is in progress it's important that a Research Office user is aware of
         * this, because it means that the application cannot be submitted until the section is marked
         * as complete. This is labelled as "Editing" in the UI for Research Office users.
         */
        return section.status.applicantStatus === 'InProgress'
          ? section.status.applicantStatus
          : section.status.researchOfficeStatus;
      }

      /**
       * If the RO marked the section as 'NeedsReview' and the applicants have not yet addressed
       * the issues and marked the section as 'Complete', show the 'NeedsReview' status to applicants
       */
      if (section.status.researchOfficeStatus === 'NeedsReview') {
        return section.status.researchOfficeStatus;
      }

      return section.status.applicantStatus;
    } catch {
      return undefined;
    }
  };

  return {
    status: getSectionStatus(),
    isLoading,
  };
};

export default useRoleBasedSectionStatus;
