import { useRouter } from 'next/router';
import Script from 'next/script';
import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import AnalyticsContext from 'components/AnalyticsContext';
import Button from 'components/Button';

import CONSTANTS from 'utils/constants';
import { cookiePermissionRequestText, getCivicUkConfig } from 'utils/get-civic-uk-config';
import type { CivicUkConfig } from 'utils/get-civic-uk-config';
import URL_LIBRARY from 'utils/url-library';

const PATHS_TO_EXCLUDE: string[] = [
  URL_LIBRARY.FUNDING_PLATFORM.HEALTH_CHECK,
  URL_LIBRARY.FUNDING_PLATFORM.OAUTH_CALLBACK,
];

export const CookiesBanner = () => {
  const { setHasAcceptedAnalytics, setHasAcceptedMarketing, cookieControl, setCookieControl } =
    useContext(AnalyticsContext);
  const [civicUkConfig, setCivicUkConfig] = useState<CivicUkConfig>(null);
  const [isClient, setClient] = useState(false);
  const [isActive, setActive] = useState(false);
  const [hasSetPreferences, setHasSetPreferences] = useState(false);

  const [cookies] = useCookies([CONSTANTS.COOKIES.TRACKING_COOKIE.NAME]);
  const router = useRouter();
  const trackingCookie = cookies[CONSTANTS.COOKIES.TRACKING_COOKIE.NAME];

  const civicApiKey = process.env.NEXT_PUBLIC_CIVIC_API_KEY;

  const handleShowCivic = () => {
    setHasSetPreferences(true);
    cookieControl?.open();
  };

  const handleAcceptAll = () => {
    setHasSetPreferences(true);
    setHasAcceptedAnalytics(true);
    setHasAcceptedMarketing(true);
    cookieControl?.acceptAll();
  };

  const handleAcceptEssentialOnly = () => {
    setHasSetPreferences(true);
    setHasAcceptedAnalytics(false);
    setHasAcceptedMarketing(false);
    cookieControl?.rejectAll();
  };

  const handleOnReady = () => {
    // @ts-ignore
    setCookieControl(window.CookieControl);
  };

  useEffect(() => {
    /*
     * We can show the banner if:
     * - the user is on the client
     * - the user has not accepted or rejected cookies
     * - the user is not on a page that should not show the banner
     */
    const canShow =
      isClient &&
      (trackingCookie === null || typeof trackingCookie === 'undefined') &&
      !PATHS_TO_EXCLUDE.includes(router.asPath.split('?')[0]) &&
      !hasSetPreferences;
    setActive(canShow);
  });

  useEffect(() => {
    setClient(true);
  }, []);

  useEffect(() => {
    if (cookieControl && civicUkConfig) {
      cookieControl?.load(civicUkConfig);
    }
  }, [cookieControl, civicUkConfig]);

  useEffect(() => {
    setCivicUkConfig(getCivicUkConfig(civicApiKey, setHasAcceptedAnalytics, setHasAcceptedMarketing));
  }, [civicApiKey]);

  return (
    <>
      <Script src={URL_LIBRARY.EXTERNAL.CIVIC_COOKIE_SCRIPT} onReady={handleOnReady} strategy="afterInteractive" />
      {isActive && (
        <section
          aria-label="Cookies on Wellcome Funding"
          className="fixed bottom-0 w-full px-responsiveLg py-responsiveMd xl:flex flex-wrap bg-amber-10 z-[100] translate-z-1 shadow-footer-bar"
        >
          <h2 className="basis-full text-heading-lg font-medium mb-staticMd mt-0">Our website uses cookies</h2>
          <p className="mb-staticLg xl:mb-0 xl:max-w-[50%]">{cookiePermissionRequestText}</p>
          <div className="flex flex-wrap ml-auto">
            <Button
              className="mb-staticSm xs:mb-0 mr-staticLg bg-transparent active:bg-blue-05"
              onClick={handleShowCivic}
              variant="secondary"
              id="manage-cookie-preferences"
            >
              Manage cookies
            </Button>
            <Button
              className="mb-staticSm xs:mb-0 mr-staticLg bg-transparent active:bg-blue-05"
              onClick={handleAcceptEssentialOnly}
              variant="secondary"
              id="manage-cookie-preferences"
            >
              <span className="sr-only">Allow </span>Essential only{' '}
              <span className="sr-only">and close cookies banner</span>
            </Button>
            <Button variant="primary" onClick={handleAcceptAll} id="accept-and-close-cookie-banner">
              Accept all <span className="sr-only">and close cookies banner</span>
            </Button>
          </div>
        </section>
      )}
    </>
  );
};

export default CookiesBanner;
