export const isInternalHref = (url: string) => {
  try {
    if (!url) {
      throw new Error(`TypeError: url should be string, ${typeof url} provided.`);
    }

    const a = document.createElement('a');
    a.href = url;
    return a.host === window.location.host;
  } catch (error) {
    return false;
  }
};

export default isInternalHref;
