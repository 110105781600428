import { Dispatch, SetStateAction } from 'react';
import TagManager from 'react-gtm-module';

import URL_LIBRARY from 'utils/url-library';

type DesignSystemColours = {
  [key: string]: string;
};

export type CivicUkConfig = {
  [key: string]: any;
};

const analyticsCookies = ['Collect', '_ga', '_gid', '_gat', '_ga*'];

const necessaryCookies = [
  'CookieConsent', // CivicUK
  'expert-review-before-start-viewed-*', // Expert review
  '_GRECAPTCHA', // Google reCAPTCHA
  'hide-global-banner', // Global banner within Funplat
  'token', // Auth token
  'hide-diversity-monitoring-banner', // Diversity monitoring banner within Funplat
];

// const marketingCookies = ['Personalization_id', '_gcl_au', 'lms_ads', 'UserMatchHistory'];

const designSystemColours: DesignSystemColours = {
  amber10: '#fff4d1',
  amber30: '#fec200',
  blue70: '#004099',
  grey80: '#292929',
  grey90: '#000',
  white: '#fff',
};

const branding = {
  removeAbout: true,
  removeIcon: true,
  fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: '0.9375rem',
  fontSizeHeaders: '1.175rem',
  fontColor: designSystemColours.grey80,
  backgroundColor: designSystemColours.amber10,
  acceptBackground: designSystemColours.blue70,
  acceptText: designSystemColours.white,
  rejectText: designSystemColours.grey80,
  toggleColor: '#0055cc',
  toggleBackground: designSystemColours.amber10,
  toggleText: designSystemColours.grey90,
};

export const cookiePermissionRequestText =
  'We use cookies to make our website work. To help us make our website better, we’d like your consent to use cookies on behalf of third parties too.';

/*
 * Should your privacy policy change after a user gives consent,
 * Cookie Control will invalidate prior records of consent and seek the user's preferences using the latest information available.
 * https://www.civicuk.com/cookie-control/documentation/cookies
 */
const statement = {
  description: `${cookiePermissionRequestText}<br/>You can read more about how we use cookies in our`,
  name: 'Cookie policy.',
  url: URL_LIBRARY.FUNDING_PLATFORM.COOKIE_POLICY,
  updated: '08/03/2021',
};

const text = {
  title: `Manage cookies`,
  intro: null,
  necessaryTitle: `Essential cookies`,
  necessaryDescription: 'These cookies are necessary for our website to function and therefore always need to be on.',
  closeLabel: '<span style="font-weight: normal;">Save and close</span>',
  settings: 'Manage cookies',
  reject: 'Essential only',
  rejectSettings: 'Essential only',
  accept: 'Accept all',
  acceptSettings: 'Accept all',
};

export const getCivicUkConfig = (
  apiKey: string,
  setHasAcceptedAnalytics: Dispatch<SetStateAction<boolean>>,
  setHasAcceptedMarketing: Dispatch<SetStateAction<boolean>>
): CivicUkConfig => ({
  apiKey,
  product: 'PRO_MULTISITE',
  initialState: 'closed',
  consentCookieExpiry: 182,
  layout: 'popup',
  theme: 'light',
  setInnerHTML: true,
  closeStyle: 'button',
  settingsStyle: 'button',
  necessaryCookies,
  optionalCookies: [
    {
      name: 'analytics',
      label: `Cookies that measure website use`,
      description: `<ul>
        <li>We use these cookies to recognise you, to count your visits to the website, and to see how you move around it.</li>
        <li>They help us to provide you with a good experience while you browse, for example by helping to make sure you can find what you need.</li>
        <li>They also allows us to improve the way the website works.</li>
        </ul>`,
      cookies: analyticsCookies,
      onAccept() {
        setHasAcceptedAnalytics(true);
        TagManager.dataLayer({
          dataLayer: [
            'consent',
            'update',
            {
              analytics_storage: 'granted',
            },
          ],
        });
      },
      onRevoke() {
        setHasAcceptedAnalytics(false);
        TagManager.dataLayer({
          dataLayer: [
            'consent',
            'update',
            {
              analytics_storage: 'denied',
            },
          ],
        });
      },
    },
  ],
  branding,
  statement,
  subDomains: false,
  text,
  toggleType: 'slider',
});

export default getCivicUkConfig;
