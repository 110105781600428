import iconMapping from '@wellcometrust/design-system/dist/components/Icon/iconMapping'; // eslint-disable-line import/no-unresolved

import URL_LIBRARY from '../../utils/url-library';

import { PermissionType } from 'hooks/usePermissions';

type GlobalNavItem = {
  id: string;
  icon: keyof typeof iconMapping;
  path: string;
  title: string;
};

export const globalNavLinksForApplicant: Array<GlobalNavItem> = [
  {
    id: 'schemes',
    icon: 'iconContentApplications',
    path: URL_LIBRARY.FUNDING_PLATFORM.SCHEMES,
    title: 'Open funding rounds',
  },
  {
    id: 'applications',
    icon: 'iconContentForm',
    path: URL_LIBRARY.FUNDING_PLATFORM.APPLICATIONS,
    title: 'My applications',
  },
  {
    id: 'funding',
    icon: 'iconContentFunding',
    path: URL_LIBRARY.FUNDING_PLATFORM.AWARDED_GRANTS,
    title: 'Awarded grants',
  },
];

export const globalNavLinksForResearchOffice: Array<GlobalNavItem> = [
  {
    id: 'applications',
    icon: 'iconContentForm',
    path: URL_LIBRARY.FUNDING_PLATFORM.APPLICATIONS,
    title: 'Applications',
  },
  {
    id: 'funding',
    icon: 'iconContentFunding',
    path: URL_LIBRARY.FUNDING_PLATFORM.AWARDED_GRANTS,
    title: 'Awarded grants',
  },
  {
    id: 'finance',
    icon: 'iconContentReporting',
    path: URL_LIBRARY.FUNDING_PLATFORM.FINANCIAL_REPORTING,
    title: 'Financial reporting',
  },
  {
    id: 'team',
    icon: 'iconSocialGroups',
    path: URL_LIBRARY.FUNDING_PLATFORM.TEAM,
    title: 'Team',
  },
];

export const expertReviewsNavLink: GlobalNavItem = {
  id: 'expert-reviews',
  icon: 'iconContentPersonStar',
  path: URL_LIBRARY.FUNDING_PLATFORM.EXPERT_REVIEWS,
  title: 'Expert reviews',
};

export const committeesNavLink: GlobalNavItem = {
  id: 'committees',
  icon: 'iconSocialGroups',
  path: URL_LIBRARY.FUNDING_PLATFORM.COMMITTEES,
  title: 'Committees',
};

export const getGlobalNavLinks = (permissions: PermissionType, hasExpertReviews: boolean, hasCommittees: boolean) => {
  if (permissions === 'OFFICE') {
    return globalNavLinksForResearchOffice;
  }

  const additionalNavItems = [];

  if (hasExpertReviews) additionalNavItems.push(expertReviewsNavLink);
  if (hasCommittees) additionalNavItems.push(committeesNavLink);

  if (additionalNavItems.length) return [...globalNavLinksForApplicant, ...additionalNavItems];

  return globalNavLinksForApplicant;
};

export default getGlobalNavLinks;
