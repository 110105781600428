import { Icon } from '@wellcometrust/design-system';
import cx from 'classnames';
import Link from 'next/link';
import { useState } from 'react';

import URL_LIBRARY from '../../utils/url-library';

import Popup from 'components/Popup';

type UserMenuProps = {
  user: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
};

export const UserMenu = ({ user }: UserMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const getSummary = () => (
    <>
      {user.firstName && user.lastName ? (
        <span aria-hidden="true">
          {user.firstName.charAt(0)}
          {user.lastName.charAt(0)}
        </span>
      ) : (
        <Icon className="w-md h-md mx-auto menuUserAvatar" name="iconSocialPerson" aria-hidden="true" />
      )}
      <span className="sr-only">Account menu</span>
    </>
  );

  return (
    <Popup
      className="flex relative"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      summaryChildren={getSummary()}
      summaryClassName="flex flex-col justify-center w-[44px] h-[44px] font-bold text-center leading-none bg-amber-20 rounded-full has-solid-background hover:border-2 hover:border-solid hover:border-grey-80 open:border-2 open:border-solid open:border-grey-80 focus-visible:border-2 focus-visible:border-solid focus-visible:border-grey-80 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-blue-60 focus:outline-none"
      summaryAriaLabel="Account menu"
      data-cy="account-menu"
    >
      <div
        className={cx(
          'block w-[270px] xs:w-[300px] absolute right-0 bg-white rounded-sm will-change-opacity mt-staticXxs',
          {
            'drop-shadow-md border-1 border-grey-20': isOpen,
          }
        )}
      >
        <ul className="list-none p-0 m-0">
          <li className="p-0 m-0 border-b-1 border-b-solid border-grey-20">
            <span aria-label="Username" className="block p-staticMd w-full text-left text-grey-60 break-words">
              {user.email}
            </span>
          </li>
          {[
            { label: 'Personal details', url: URL_LIBRARY.FUNDING_PLATFORM.USER.MANAGE.HOME },
            { label: 'Account settings', url: URL_LIBRARY.FUNDING_PLATFORM.USER.SETTINGS.HOME },
            { label: 'Logout', url: URL_LIBRARY.FUNDING_PLATFORM.LOGOUT.HOME },
          ].map(({ label, url }) => (
            <li className="mb-0" key={label}>
              <Link
                href={url}
                className="block p-staticMd w-full text-left focus:bg-blue-70 hover:bg-blue-70 hover:text-white focus:text-white active:bg-blue-80"
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Popup>
  );
};

export default UserMenu;
