import { ReactNode, useState } from 'react';

import { AnalyticsContext } from './AnalyticsContext';
import type { AnalyticsContextProps } from './AnalyticsContext';

type AnalyticsContextProviderProps = {
  children: ReactNode;
};

export const AnalyticsContextProvider = ({ children }: AnalyticsContextProviderProps) => {
  const [hasAcceptedAnalytics, setHasAcceptedAnalytics] =
    useState<AnalyticsContextProps['hasAcceptedAnalytics']>(false);
  const [hasAcceptedMarketing, setHasAcceptedMarketing] =
    useState<AnalyticsContextProps['hasAcceptedMarketing']>(false);
  const [cookieControl, setCookieControl] = useState<AnalyticsContextProps['cookieControl']>(null);

  return (
    <AnalyticsContext.Provider
      value={{
        hasAcceptedAnalytics,
        setHasAcceptedAnalytics,
        hasAcceptedMarketing,
        setHasAcceptedMarketing,
        cookieControl,
        setCookieControl,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsContextProvider;
