import { Icon } from '@wellcometrust/design-system';
import { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import Link from 'next/link';

import RestrictedContent from 'components/RestrictedContent';
import Tooltip from 'components/Tooltip';
import WellcomeLogo from 'components/WellcomeLogo';

import useGlobalNavBehaviour from 'hooks/useGlobalNavBehaviour';
import URL_LIBRARY from 'utils/url-library';

type GlobalNavProps = {
  pageId: string;
};

export const GlobalNav = (props: GlobalNavProps) => {
  const {
    handleHamburgerClick,
    handleHamburgerMouseEnter,
    handleHamburgerMouseLeave,
    handleMouseEnterMenu,
    handleMouseOverMenu,
    handleMouseLeaveMenu,
    handleTouchStart,
    handleShowUnlockToolTip,
    handleHideUnlockToolTip,

    handleShowLockToolTip,
    handleHideLockToolTip,

    showMenuUnlock,
    showLockToolTip,
    showUnlockToolTip,
    showLockNavIndicator,
    isMenuLockedOpen,
    isMenuOverlayVisible,
    user,
    globalNavLinks,

    isLarge,
    isTouchDevice,

    showMenuLock,

    closeButtonRef,
    openButtonRef,
  } = useGlobalNavBehaviour();

  const isMenuAccessible =
    ((isMenuLockedOpen || isMenuOverlayVisible) && isLarge) || (isMenuOverlayVisible && !isLarge);

  return (
    <>
      <button
        aria-controls="global-nav"
        aria-expanded={!(isMenuLockedOpen && isLarge) && isMenuAccessible}
        aria-hidden={isMenuLockedOpen && isLarge}
        aria-label={isLarge ? 'Open Global navigation sidebar' : 'Global navigation'}
        className={cx(
          'fixed h-[48px] w-[48px] z-50 top-[14px] left-[5%] md:left-staticXxl duration-300 transition-spacing cursor-pointer has-current-colour',
          {
            hidden: isMenuLockedOpen && isLarge,
          }
        )}
        onBlur={handleHideLockToolTip}
        onClick={handleHamburgerClick}
        onFocus={() => handleShowLockToolTip}
        onMouseEnter={handleHamburgerMouseEnter}
        onMouseLeave={handleHamburgerMouseLeave}
        onTouchStart={handleTouchStart}
        ref={openButtonRef}
        tabIndex={isMenuLockedOpen && isLarge ? -1 : 0}
        type="button"
      >
        {(showLockNavIndicator || isTouchDevice) && isLarge ? (
          <Tooltip
            className="z-50"
            id="lock-open-sidebar-tooltip"
            dialogClassName={cx('!ml-[0.75rem] transition-opacity duration-300 z-50', {
              'opacity-100': showLockToolTip,
            })}
            position="right"
            label={
              <div>
                <span className="flex items-center">
                  <Icon name="iconActionNav" className="flex-initial" aria-hidden="true" />
                  <div className="flex-1">
                    <span className="block" role="button" aria-label="Lock sidebar open">
                      <Icon
                        className={cx(
                          'absolute top-1/2 mt-[calc(-1.5*var(--space-unit))] opacity-0 transition-opacity duration-300',
                          {
                            'block opacity-100 overflow-visible': (showLockToolTip || showMenuLock) && !isTouchDevice,
                          }
                        )}
                        name="iconDirectionalChevronRight"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                </span>
              </div>
            }
            tabbable={false}
          >
            <span aria-hidden="true">Lock sidebar open</span>
          </Tooltip>
        ) : (
          <span className="flex items-center">
            <Icon name="iconActionNav" className="flex-initial" aria-hidden="true" />
          </span>
        )}
      </button>
      <FocusTrap
        active={(isLarge && isMenuOverlayVisible && !isMenuLockedOpen) || (!isLarge && isMenuOverlayVisible)}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          escapeDeactivates: true,
          initialFocus: `#nav-${globalNavLinks[0].id}`,
        }}
      >
        <div className="relative z-50 flex items-center">
          <div
            className={cx(
              'flex flex-col justify-start transition-spacing c-nav-side-bar text-grey-80',
              { '-ml-nav': !isMenuOverlayVisible && (!isMenuLockedOpen || !isLarge) },
              { 'c-nav-side-bar--is-locked': isMenuLockedOpen && isLarge },
              { 'c-nav-side-bar--is-not-locked': !isMenuLockedOpen },
              {
                'c-nav-side-bar--is-overlay': isMenuOverlayVisible && (!isMenuLockedOpen || !isLarge),
              }
            )}
            onMouseLeave={handleMouseLeaveMenu}
            onMouseEnter={handleMouseEnterMenu}
            onMouseOver={handleMouseOverMenu}
            onFocus={handleMouseEnterMenu}
            aria-hidden={!isMenuOverlayVisible && (!isMenuLockedOpen || !isLarge)}
            aria-expanded={isMenuLockedOpen && isMenuOverlayVisible}
            aria-label="Global navigation"
            id="global-nav"
          >
            <div className="flex min-h-[72px] px-staticMd items-center">
              <WellcomeLogo
                className="flex-initial w-staticXl h-staticXl mr-staticXs has-current-colour"
                labelledBy="site-header-logo"
                title="Wellcome"
              />
              <span className="font-bold text-body-sm font-secondary flex-1 text-grey-80">Wellcome Funding</span>
              <button
                aria-controls="global-nav"
                aria-describedby="close-sidebar-tooltip"
                aria-hidden={!(isMenuLockedOpen && isLarge)}
                aria-label="Close sidebar"
                className={cx('flex-end cursor-pointer opacity-0 has-current-colour -mr-staticMd', {
                  hidden: !(isMenuLockedOpen && isLarge),
                  'block opacity-100': showMenuUnlock || isTouchDevice,
                })}
                onBlur={handleHideUnlockToolTip}
                onClick={handleHamburgerClick}
                onFocus={handleShowUnlockToolTip}
                onMouseEnter={handleShowUnlockToolTip}
                onMouseLeave={handleHideUnlockToolTip}
                ref={closeButtonRef}
                tabIndex={!(isMenuLockedOpen && isLarge) ? -1 : 0}
                type="button"
              >
                {/*
                    This <span> wrapper prevents a rendering bug in FireFox whereby the Tooltip
                    text is sometimes not rendered if the overflow classes are applied to the
                    button itself.
                    */}
                <span
                  className={cx('block overflow-hidden', {
                    'overflow-visible': showMenuUnlock || isTouchDevice,
                  })}
                >
                  <Tooltip
                    id="close-sidebar-tooltip"
                    className="pr-staticMd pl-staticXxs py-[10px] overflow-visible"
                    dialogClassName={cx('!ml-0 transition-opacity duration-300 overflow-visible', {
                      'opacity-100': showUnlockToolTip,
                    })}
                    position="right"
                    label={<Icon name="iconActionCloseNav" />}
                    tabbable={false}
                  >
                    Close sidebar
                  </Tooltip>
                </span>
              </button>
            </div>
            <nav
              className="border-b-1 border-solid border-amber-10 overflow-x-hidden overflow-y-auto"
              aria-label="Global"
            >
              <ul className="list-none">
                {globalNavLinks.map((item) => (
                  <li id={`nav-${item.id}`} key={`nav-${item.id}`} className="mb-0 flex">
                    <Link
                      aria-current={item.id === props.pageId ? 'page' : undefined}
                      aria-hidden={!isMenuAccessible}
                      href={item.path}
                      className="c-nav-side-bar__item flex w-full items-top py-staticMd"
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                      tabIndex={isMenuAccessible ? 0 : -1}
                    >
                      {item.icon && (
                        <Icon
                          className="flex-initial has-current-colour mr-responsiveSm"
                          name={item.icon as IconProps['name']}
                        />
                      )}
                      <span className="flex-1">
                        <span
                          className={cx('c-nav-side-bar__item-text', {
                            'c-nav-side-bar__item-text--is-active': item.id === props.pageId,
                          })}
                        >
                          {item.title}
                        </span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="mt-auto border-t-1 border-t-solid border-amber-10">
              <ul className="list-none m-0 p-0">
                <li id="nav-help" key="nav-help" className="m-0 p-0 flex ">
                  <Link
                    aria-hidden={!isMenuAccessible}
                    href={URL_LIBRARY.WELLCOME_MAIN_SITE.HELP_AND_SUPPORT}
                    className="c-nav-side-bar__item flex w-full items-top p-staticMd"
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={isMenuAccessible ? 0 : -1}
                  >
                    <span className="flex-1">
                      <span className="c-nav-side-bar__item-text">Help and support</span>
                    </span>
                  </Link>
                </li>
              </ul>
              <RestrictedContent permission="OFFICE">
                {user.organisation && (
                  <div
                    className="bg-white border-1 border-solid border-amber-10 mx-staticMd mb-staticMd p-staticMd text-body-sm text-center font-bold"
                    data-testid="research-organisation-name"
                  >
                    {user.organisation.name}
                  </div>
                )}
              </RestrictedContent>
            </div>
          </div>
        </div>
      </FocusTrap>
    </>
  );
};

export default GlobalNav;
