import { useFloating, shift, limitShift, autoUpdate } from '@floating-ui/react-dom';
import { Icon } from '@wellcometrust/design-system';
import cx from 'classnames';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';

import TooltipDialog from './TooltipDialog';

export type TooltipPosition = 'bottom' | 'right' | 'top' | 'left';

const FLOATING_TOOLTIP_OFFSET = 12;

type TooltipProps = {
  children: ReactNode;
  className?: string;
  dialogClassName?: string;
  id: string;
  label?: string | ReactNode;
  position?: TooltipPosition;
  tabbable?: boolean;
  isShifting?: boolean;
  inTable?: boolean;
  hasLabel?: boolean;
};

export const Tooltip = ({
  children,
  className,
  dialogClassName,
  id,
  label,
  isShifting,
  position = 'bottom',
  tabbable = true,
  hasLabel = true,
  inTable = false,
}: TooltipProps) => {
  /* We could set these as a single state object, but that seems to trigger a maximum update depth error */
  const [tooltipTop, setTooltipTop] = useState<number>();
  const [tooltipLeft, setTooltipLeft] = useState<number>();
  const [tooltipRight, setTooltipRight] = useState<number>();

  const { x, y, reference, floating, update, refs } = useFloating({
    placement: position,
    middleware: [
      shift({
        limiter: limitShift({
          offset: 25,
        }),
      }),
    ],
  });

  const ref = useRef(null);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update]);

  /*
   * If the tooltip is in a table, because the table is scrollable horizontally,
   * we need to set the table's overflow-y to clip because setting overflow-y to
   * visible doesn't work, and any other setting will cause the table to be
   * scrollable vertically.
   *
   * This means that the tooltip will potentially be clipped by the table's
   * overflow-y: clip, so we need to calculate the tooltip's trigger's offset
   * from the top of the document and set the tooltip's positioning to fixed
   * and then its top position to the calculated offset.
   */
  useLayoutEffect(() => {
    if (!inTable) return;
    const { top, right, left } = ref.current.getBoundingClientRect();
    setTooltipTop(parseInt(top, 10) + FLOATING_TOOLTIP_OFFSET);
    setTooltipLeft(parseInt(left, 10));
    setTooltipRight(parseInt(right, 10));
  });

  const tooltipStyle = {
    top: `${tooltipTop}px`,
    left: `${tooltipLeft}px`,
    right: `${tooltipRight}px`,
  };

  return (
    <div
      aria-describedby={id}
      className={cx('relative group', className)}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={tabbable ? 0 : -1}
      ref={reference}
      role="button"
    >
      {hasLabel && (
        <span ref={ref}>
          {label || <Icon className="text-body-xs h-[1.1em] has-current-colour" name="iconActionQuestion" />}
        </span>
      )}
      <div className={cx({ 'fixed z-30': inTable })} style={inTable ? tooltipStyle : null}>
        <TooltipDialog
          position={position}
          reference={floating}
          isShifting={isShifting}
          id={id}
          shiftingX={x}
          shiftingY={y}
          className={`invisible group-hover:visible group-focus:visible ${dialogClassName || ''}`}
        >
          {children}
        </TooltipDialog>
      </div>
    </div>
  );
};

export default Tooltip;
