import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';

import AnalyticsContext from 'components/AnalyticsContext';

import CONSTANTS from 'utils/constants';

export const useInitAnalytics = () => {
  const [cookies] = useCookies([CONSTANTS.COOKIES.TRACKING_COOKIE.NAME]);
  const trackingCookie = cookies[CONSTANTS.COOKIES.TRACKING_COOKIE.NAME];
  const { hasAcceptedAnalytics, hasAcceptedMarketing } = useContext(AnalyticsContext);

  const initAnalytics = () => {
    if (!process.env.NEXT_PUBLIC_GTM_ID) {
      /**
       * Throwing an Error inside a React mounting lifecycle method will cause React
       * to get stuck in a render loop in production, use console.error here
       * instead for safety.
       *
       * @see {@link https://reactjs.org/blog/2017/07/26/error-handling-in-react-16}
       * @see {@link https://reactjs.org/docs/react-component.html#componentdidcatch}
       */
      console.error('GTM_ID is missing. It is required for Google Tag Manager to initialise.');
    } else {
      // Conditionally initialise to prevent superfluous network request
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      });
    }
  };

  useEffect(() => {
    const analyticsPermission =
      trackingCookie?.optionalCookies?.analytics === 'accepted' || hasAcceptedAnalytics ? 'accepted' : 'denied';
    const marketingPermission =
      trackingCookie?.optionalCookies?.marketing === 'accepted' || hasAcceptedMarketing ? 'accepted' : 'denied';

    TagManager.dataLayer({
      dataLayer: [
        'consent',
        'default',
        {
          ad_storage: marketingPermission,
          ad_user_data: marketingPermission,
          ad_personalization: marketingPermission,
          analytics_storage: analyticsPermission,
        },
      ],
    });

    if (
      !hasAcceptedAnalytics &&
      (typeof trackingCookie?.optionalCookies === 'undefined' ||
        trackingCookie?.optionalCookies?.analytics !== 'accepted')
    ) {
      return;
    }

    initAnalytics();
  }, [cookies, hasAcceptedAnalytics, hasAcceptedMarketing]);
};

export default useInitAnalytics;
