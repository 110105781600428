import { FormFileDetails } from './application-overview';
import { ApiResponse } from './types';
import { UserName, UserNameEmail } from './user-name';

import CONSTANTS from 'utils/constants';
import fetchWithToken from 'utils/fetchers';

/*
 * REQUESTED: Expert reviewer has been invited to do the review but they have not accepted yet
 * IN_REVIEW: Expert reviewer accepted but they might have a conflict of interest or asked for an extension, which must be reviewed by the funding manager
 * RECEIVED and COMPLETE: Expert review was submitted to Wellcome
 */
export type ExpertReviewStatus = 'REQUESTED' | 'IN_REVIEW' | 'IN_PROGRESS' | 'RECEIVED' | 'COMPLETE' | 'NOT_PROGRESSED';

export type ExpertReview = {
  expertReviewId: string;
  reference: string;
  applicationTitle: string;
  confidentialityAgreementStatus: string;
  schemeTitle: string;
  roundDisplayName: string;
  leadApplicant: UserName;
  formId: string;
  fundingManager?: UserNameEmail;
  dueDate: string;
  completedApplicationForm?: FormFileDetails;
  invitationCode: string;
  invitationDueDate: string;
  status: ExpertReviewStatus;
  isOverdue: boolean;
  submittedDate: string;
};

export const getAllExpertReviews = async () => {
  const response: ApiResponse<ExpertReview[]> = await fetchWithToken(`api/${CONSTANTS.API_VERSION}/expert-review`);

  return response;
};

export const getExpertReview = async (id: string) => {
  const response: ApiResponse<ExpertReview> = await fetchWithToken(`api/${CONSTANTS.API_VERSION}/expert-review/${id}`);

  return response;
};

export const submitExpertReview = async (expertReviewId: string) => {
  const { success, errors } = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/expert-review/${expertReviewId}:submit`,
    { method: 'POST' }
  );

  return {
    success,
    errors,
  };
};
