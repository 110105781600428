import cx from 'classnames';
import { useContext } from 'react';

import MenuContext from 'components/MenuContext';
import ViewportContext from 'components/ViewportContext';

/**
 * A hook calculating the left padding required to account for the width of primary and secondary navs
 */
export const useNavbarPadding = (hasSecondaryNav: boolean = false) => {
  const { isMenuLockedOpen } = useContext(MenuContext);
  const { isLarge, isMobile } = useContext(ViewportContext);

  return cx({
    'pl-nav': isMenuLockedOpen && isLarge && !hasSecondaryNav,
    'pl-nav-both': isMenuLockedOpen && isLarge && !isMobile && hasSecondaryNav,
    'pl-nav-form': !isLarge && !isMobile && hasSecondaryNav,
    'pl-nav-form-only': !isMenuLockedOpen && isLarge && !isMobile && hasSecondaryNav,
  });
};

export default useNavbarPadding;
