import FancyLink from 'components/FancyLink';
import ExtendedFooter from 'components/Footer/ExtendedFooter';
import { Container, Grid, GridCell, PageWrapper, MainContent, MenuBar } from 'components/NewLayout';
import Meta from 'components/NewLayout/Meta';
import PageHeader from 'components/PageHeader';

import URL_LIBRARY from 'utils/url-library';

const PAGE_TITLE = 'Sorry, there’s a problem with the Wellcome Funding service';

const ErrorPage = () => (
  <PageWrapper className="min-h-screen flex flex-col justify-between">
    <Meta title={PAGE_TITLE} />
    <MenuBar />
    <MainContent>
      <Container maxWidth="md" className="pb-responsiveXxl">
        <Grid>
          <GridCell className="col-span-full md:col-span-8">
            <PageHeader title={PAGE_TITLE} className="mb-responsiveMd" hideStatusBadge />
            <p className="mb-responsiveMd text-body-lg">Try again later.</p>
            <p>
              <FancyLink href={URL_LIBRARY.WELLCOME_MAIN_SITE.CONTACT_US_GRANT_TRACKER}>Contact us</FancyLink> if you
              need help with your application
            </p>
          </GridCell>
        </Grid>
      </Container>
    </MainContent>
    <ExtendedFooter />
  </PageWrapper>
);
export default ErrorPage;
