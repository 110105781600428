import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import useOnKeyDown from '../../hooks/useOnKeyDown';

export type BreadcrumbsProps = {
  className?: string;
  crumbs?: {
    crumbText: string;
    href: string;
  }[];
  pageTitle: string;
};

export const Breadcrumbs = ({ className, crumbs, pageTitle }: BreadcrumbsProps) => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>();
  const [isDisclosed, setIsDisclosed] = useState(false);

  useOnClickOutside(ref, () => setIsDisclosed(false));

  // removes id e.g. #main from the URL
  const cleanedPath: string = router.asPath.replace(/#.*/g, '');

  const handleDisclose = () => {
    ref.current.blur();
    setIsDisclosed(!isDisclosed);
  };

  const handleCurrentPageClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  useOnKeyDown(['Esc', 'Escape'], () => setIsDisclosed(false));

  return (
    <nav
      aria-label="Breadcrumbs"
      className={cx('w-full z-0', {
        [className]: className,
      })}
    >
      <div
        role="list"
        className="c-breadcrumbs items-center justify-start p-0 ml-0 flex mb-0 last:mb-0 relative list-none"
        ref={ref}
      >
        {crumbs && crumbs.length > 0 && (
          <>
            <div className="c-breadcrumbs__item--indicator" key="breadcrumb-reveal" role="listitem">
              <span className="c-breadcrumbs__item-link">
                <button
                  aria-label="Show breadcrumb items"
                  className={cx({
                    'border-1 border-solid border-grey-80': isDisclosed,
                    'border-1 border-solid border-white': !isDisclosed,
                  })}
                  type="button"
                  onClick={handleDisclose}
                >
                  …
                </button>
              </span>
            </div>
            <div
              aria-label="Breadcrumb items"
              key="trail-wrapper"
              className={cx('c-breadcrumbs__dropdown-menu', {
                'block drop-shadow-md border-1 border-grey-20 border-solid': isDisclosed,
              })}
              role="group"
            >
              {crumbs.map((breadcrumb, index) => (
                <div
                  role="listitem"
                  aria-label={String(index + 1)}
                  className="c-breadcrumbs__item"
                  key={breadcrumb.href}
                >
                  <Link href={breadcrumb.href} className="c-breadcrumbs__item-link">
                    <span className="bg-gradient-to-r from-current to-current bg-underline-none bg-left-bottom bg-no-repeat no-underline transition-all duration-300 ease-in-out hover:bg-underline-thin">
                      <span className="max-w-[20ch] sm:max-w-[27ch] truncate block">{breadcrumb.crumbText}</span>
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
        <div
          role="listitem"
          aria-label={crumbs?.length > 0 ? String(crumbs.length + 1) : '1'}
          className="c-breadcrumbs__item--current"
          key={cleanedPath}
        >
          <a
            className="max-w-[24ch] cursor-default sm:max-w-[28ch] block truncate"
            aria-current="page"
            href={cleanedPath}
            onClick={handleCurrentPageClick}
          >
            {pageTitle}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Breadcrumbs;
