import Link from 'next/link';

type MainContentProps = {
  children: React.ReactNode;
  className?: string;
};

const MainContent = (props: MainContentProps) => (
  <>
    <Link
      aria-hidden="false"
      href="#main-content-target"
      id="main-content-target"
      className="-translate-y-full focus:translate-y-0 z-50 absolute top-0 left-2/4 -translate-x-1/2 bg-blue-70 rounded-b-sm rounded-t-none p-sm z-[90] text-white"
    >
      Start of main content
    </Link>
    <main className={props.className} id="main-content">
      {props.children}
    </main>
  </>
);

export default MainContent;
