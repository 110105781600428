import useApplicationOverview from './useApplicationOverview';
import useStatus from './useStatus';

import { ApplicationOverview } from 'utils/api/application-overview';

export const useCurrentParticipant = (id: string) => {
  const { user } = useStatus();
  const { data, mutate } = useApplicationOverview(id);

  const users = data?.participants;
  const currentUser = users?.find((cur) => cur.userId === user?.id);

  return {
    user: currentUser,
    mutate: (newUser: ApplicationOverview['participants'][0]) => {
      /**
       * Remove the current user from the list of participants, because we're going to add
       * them back in with the updated data.
       */
      const participants = data.participants.filter((cur) => cur.userId !== user?.id);

      mutate({
        success: true,
        data: {
          ...data,
          participants: [...participants, newUser],
        },
      });
    },
  };
};

export default useCurrentParticipant;
