import { AnnualReportStatus } from './api/annual-report';
import { ApplicationStatus } from './api/application';
import { AcceleratorAwardsStatus, AccountProfileStatus, PositiveActionStatus } from './api/application-overview';
import { DetailedAwardedGrantStatus } from './api/awarded-grant';
import { CommitteeAttendanceStatus } from './api/committee';
import { CommitteeLeadReviewStatus } from './api/committee-meeting';
import { CommitteeMembershipStatus } from './api/committee-membership';
import { ExpertReviewStatus } from './api/expert-review';
import { SectionStatus } from './api/section-status';

import type { PermissionType } from 'hooks/usePermissions';

/*
 * The same status values sometimes have different labels and style variants depending on what context the status is used in.
 * (For example, the application status WITH_APPLICANT has a different label depending on whether it is viewed by a research
 * office user or an applicant.) This file maps all possible status values to status labels for each possible context.
 */

export type StatusContext =
  | 'annualReport'
  | 'applicationApplicant'
  | 'applicationResearchOffice'
  | 'accountProfile'
  | 'positiveAction'
  | 'acceleratorAwards'
  | 'section'
  | 'expertReview'
  | 'membership'
  | 'meeting'
  | 'pastMeeting'
  | 'recentMeeting'
  | 'committeeLeadReview'
  | 'awardedGrantApplicant'
  | 'awardedGrantResearchOffice';

type LabelsByPermission = {
  [key in PermissionType]: string;
};

const sectionStatusLabels: {
  [key in SectionStatus['applicantStatus'] | SectionStatus['researchOfficeStatus']]: string | LabelsByPermission;
} = {
  NotStarted: 'Not started',
  InProgress: { APPLICANT: 'In progress', OFFICE: 'Editing' },
  Complete: 'Completed',
  NotChecked: 'Needs checking',
  NeedsReview: 'Changes needed',
  Checked: 'Checked',
};

const annualReportStatusLabels: {
  [key in AnnualReportStatus]: string;
} = {
  REQUESTED: 'Report due',
  RECEIVED: 'Received',
  EXPIRED: 'Completed',
};

const applicationResearchOfficeStatusLabels: {
  [key in ApplicationStatus]: string;
} = {
  NOT_AVAILABLE: 'Not available',
  INVITED: 'Invited',
  WITH_APPLICANT: 'Draft',
  WITH_RESEARCH_OFFICE: 'Needs reviewing',
  RETURNED_TO_APPLICANT: 'Sent back to applicant',
  RETURNED_TO_APPLICANT_FROM_WELLCOME: 'Sent back to applicant by Wellcome',
  WITH_WELLCOME: 'Received by Wellcome',
  RECEIVED: 'Received by Wellcome',
  SHORTLISTING: 'Shortlisting',
  EXPERT_REVIEW: 'Expert review',
  DECISION: 'Awaiting decision',
  DECISION_INTERVIEW: 'Interview',
  DECISION_RECOMMENDATION: 'Awaiting decision',
  APPROVED: 'Successful - funding offered',
  AWARD_OFFERED: 'Successful - funding offered',
  AWARD_ACCEPTED: 'Funding accepted - pending Wellcome approval',
  AWARD_STARTING_SOON: 'Funding accepted - starting soon',
  ACTIVE: 'Successful - funding active',
  COMPLETED: 'Successful - funding ended',
  CLOSED: 'Successful - funding ended',
  Unsubmitted: 'Deadline passed',
  Unsuccessful: 'Unsuccessful',
  Withdrawn: 'Withdrawn',
};

const applicationApplicantStatusLabels: { [key in ApplicationStatus]: string } = {
  NOT_AVAILABLE: 'Not available',
  INVITED: 'Invited',
  WITH_APPLICANT: 'In progress',
  WITH_RESEARCH_OFFICE: 'Sent to organisation for approval',
  RETURNED_TO_APPLICANT: 'Returned for edits',
  RETURNED_TO_APPLICANT_FROM_WELLCOME: 'Returned by Wellcome for edits',
  WITH_WELLCOME: 'Received by Wellcome',
  RECEIVED: 'Received by Wellcome',
  SHORTLISTING: 'Awaiting decision',
  EXPERT_REVIEW: 'Awaiting decision',
  DECISION: 'Awaiting decision',
  DECISION_INTERVIEW: 'Interview',
  DECISION_RECOMMENDATION: 'Awaiting decision',
  APPROVED: 'Successful',
  AWARD_OFFERED: 'Successful',
  AWARD_ACCEPTED: 'Successful',
  AWARD_STARTING_SOON: 'Successful',
  ACTIVE: 'Successful',
  COMPLETED: 'Successful',
  CLOSED: 'Successful',
  Unsubmitted: 'Deadline passed',
  Unsuccessful: 'Unsuccessful',
  Withdrawn: 'Withdrawn',
};

const awardedGrantApplicantStatusLabels: { [key in DetailedAwardedGrantStatus]: string } = {
  AWARD_OFFERED: 'Funding offered',
  AWARD_ACCEPTED: 'Funding offered', // Applicants should still see the 'Funding offered' status even after the grant was accepted by RO until Wellcome approves
  AWARD_STARTING_SOON: 'Funding accepted',
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  CLOSED: 'Closed',
  ENDED: 'Ended',
  ENDING_SOON: 'Active',
  REPORT_DUE: 'Ended',
};

const awardedGrantResearchOfficeStatusLabels: { [key in DetailedAwardedGrantStatus]: string } = {
  AWARD_OFFERED: 'Funding offered',
  AWARD_ACCEPTED: 'Funding accepted',
  AWARD_STARTING_SOON: 'Funding accepted',
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  CLOSED: 'Closed',
  ENDED: 'Ended',
  ENDING_SOON: 'Active',
  REPORT_DUE: 'Ended',
};

const accountProfileStatusLabels: { [key in AccountProfileStatus]: string } = {
  COMPLETE: 'Completed',
  NOT_COMPLETE: 'In progress',
};

const positiveActionStatusLabels: { [key in PositiveActionStatus]: string } = {
  NotStarted: 'Not started',
  InProgress: 'In progress',
  Complete: 'Completed',
};

const acceleratorAwardsStatusLabels: { [key in AcceleratorAwardsStatus]: string } = {
  NotStarted: 'Not started',
  InProgress: 'In progress',
  Complete: 'Completed',
};

const expertReviewStatusLabels: { [key in ExpertReviewStatus]: string } = {
  REQUESTED: 'Invited',
  IN_REVIEW: 'Invited',
  IN_PROGRESS: 'In progress',
  COMPLETE: 'Completed',
  RECEIVED: 'Completed',
  /*
   * In Salesforce, NOT_PROGRESSED does not always mean 'Deadline passed' (there are other reasons for a review to have this status).
   * However, Salesforce filters out all reviews which are NOT_PROGRESSED for a different reason,
   * which means we can safely label all NOT_PROGRESSED reviews as deadline passed.
   */
  NOT_PROGRESSED: 'Deadline passed',
};

const membershipStatusLabels: { [key in CommitteeMembershipStatus]: string } = {
  ACTIVE_MEMBER: 'Active member',
  PAST_MEMBER: 'Past member',
};

const meetingStatusLabels: { [key in CommitteeAttendanceStatus]: string } = {
  ATTENDED: 'Attending',
  NOT_ATTENDED: 'Not attending',
  CONTRIBUTED: 'Not attending - contributed',
};

const pastMeetingStatusLabels: { [key in CommitteeAttendanceStatus]: string } = {
  ATTENDED: 'Attended',
  NOT_ATTENDED: 'Not attended',
  CONTRIBUTED: 'Not attended - contributed',
};

const committeeLeadReviewStatusLabels: { [key in CommitteeLeadReviewStatus]: string } = {
  NOT_STARTED: 'Not started',
  IN_PROGRESS: 'In progress',
  COMPLETE: 'Completed',
};

const statusLabelsByContext = {
  annualReport: annualReportStatusLabels,
  applicationApplicant: applicationApplicantStatusLabels,
  applicationResearchOffice: applicationResearchOfficeStatusLabels,
  accountProfile: accountProfileStatusLabels,
  positiveAction: positiveActionStatusLabels,
  acceleratorAwards: acceleratorAwardsStatusLabels,
  section: sectionStatusLabels,
  expertReview: expertReviewStatusLabels,
  membership: membershipStatusLabels,
  meeting: meetingStatusLabels,
  pastMeeting: pastMeetingStatusLabels,
  recentMeeting: pastMeetingStatusLabels, // added to make the status colour for recent meetings green based on Dana's design
  committeeLeadReview: committeeLeadReviewStatusLabels,
  awardedGrantApplicant: awardedGrantApplicantStatusLabels,
  awardedGrantResearchOffice: awardedGrantResearchOfficeStatusLabels,
};

export const getStatusLabel = (
  status: string,
  context: StatusContext,
  permission: keyof LabelsByPermission = 'APPLICANT'
): string => {
  const statusMap = statusLabelsByContext[context];
  const statusLabel = statusMap[status];

  return typeof statusLabel === 'object' ? statusLabel[permission] : statusLabel;
};
