import { AccountProfileStatus, FormFileDetails, ParticipantLimits } from './application-overview';
import { UserRole } from './status';
import { UserName } from './user-name';

import type { ApiResponse } from 'utils/api/types';
import CONSTANTS from 'utils/constants';
import fetchWithToken from 'utils/fetchers';

/*
 * DISPLAY_STATUSES are the possible actual values of the web status field in Salesforce returned from the API.
 */
const DISPLAY_STATUSES = [
  'STARTED',
  'RECEIVED',
  'SHORTLISTING',
  'EXPERT_REVIEW',
  'DECISION', // This is the old Decision status, will be sent if we don't know if it's a what kind of decision it is
  'DECISION_INTERVIEW', // This is the new Decision status, will be sent if it's an interview decision
  'DECISION_RECOMMENDATION',
  'APPROVED',
  'AWARD_OFFERED',
  'AWARD_ACCEPTED',
  'AWARD_STARTING_SOON',
  'ACTIVE',
  'COMPLETED',
  'NOT_PROGRESSED',
  'CLOSED',
] as const;

/*
 * submissionStatusesSf are the available values of the submissionStatus field in Salesforce returned from the API.
 */
export const submissionStatusesSf = {
  withApplicant: 'WITH_APPLICANT',
  withResearchOffice: 'WITH_RESEARCH_OFFICE',
  withWellcome: 'WITH_WELLCOME',
  returnedToApplicant: 'RETURNED_TO_APPLICANT',
  returnedToApplicantFromWellcome: 'RETURNED_TO_APPLICANT_FROM_WELLCOME',
} as const;

/**
 * notProgressedStatusesSf are the available values of the notProgressedStatus field in Salesforce returned from the API.
 */
export const notProgressedStatusesSf = ['Withdrawn', 'Unsubmitted', 'Unsuccessful', ''] as const;

/*
 * applicationStatuses are the statuses we derive from the status field and the submissionStatus
 * and are used to sort and categorise applications in the funding platform. We map raw Application
 * objects to ApplicationWithStatus objects in the utility function mapSalesforceStatusToApplicationStatus
 * called in the useApplications hook.
 */

export const applicationStatuses = [
  'NOT_AVAILABLE',
  'INVITED',
  'WITH_APPLICANT',
  'WITH_RESEARCH_OFFICE',
  'RETURNED_TO_APPLICANT',
  'RETURNED_TO_APPLICANT_FROM_WELLCOME',
  'WITH_WELLCOME',
  'RECEIVED',
  'SHORTLISTING',
  'EXPERT_REVIEW',
  'DECISION', // This is the old Decision status, will be sent if we don't know if it's a what kind of decision it is
  'DECISION_INTERVIEW', // This is the new Decision status, will be sent if it's an interview decision
  'DECISION_RECOMMENDATION',
  'APPROVED',
  'AWARD_OFFERED',
  'AWARD_ACCEPTED',
  'AWARD_STARTING_SOON',
  'ACTIVE',
  'COMPLETED',
  'CLOSED',
  'Unsubmitted',
  'Withdrawn',
  'Unsuccessful',
] as const;

export const availabilityStatuses = ['AVAILABLE', 'NOT_AVAILABLE'] as const;

export type DisplayStatus = (typeof DISPLAY_STATUSES)[number];
export type SubmissionStatusSf = (typeof submissionStatusesSf)[keyof typeof submissionStatusesSf];
export type NotProgressedStatusSf = (typeof notProgressedStatusesSf)[number];
export type ApplicationStatus = (typeof applicationStatuses)[number];
export type AvailabilityStatus = (typeof availabilityStatuses)[number];

export type PrelimState = 'IS_PRELIM' | 'WAS_PRELIM' | null;

export type Application = {
  applicationId: string;
  awardLetterSentDate: string;
  blankFormDownloadUrl: string;
  currentUserRole: UserRole;
  currency: string;
  completedApplicationForm: FormFileDetails | null;
  dataAvailability: AvailabilityStatus;
  deadline: string;
  formId?: string;
  grantReference: string;
  grantStartDate: string | null;
  grantEndDate: string | null;
  lastUpdated: string;
  leadApplicantName: UserName;
  notProgressedStatus: NotProgressedStatusSf;
  organisationId: string;
  participants: (UserName & {
    userId?: string;
    email: string;
    invitationCode?: string;
    participantStatus: string;
    role: string;
    accountProfileStatus: AccountProfileStatus;
  })[];
  participantLimits: ParticipantLimits;
  prelimCompletedApplicationForm: FormFileDetails | null;
  prelimBlankFormDownloadUrl: FormFileDetails | null;
  prelimState: PrelimState;
  progressPercentage?: number;
  roundId?: string;
  schemeId?: string;
  schemeTitle: string;
  roundDisplayName: string;
  schemeUrl: string;
  displayStatus: DisplayStatus;
  submissionDate: string;
  submissionStatus?: SubmissionStatusSf;
  title: string;
};

export type ApplicationWithStatus = Application & {
  applicationStatus?: ApplicationStatus;
};

export const getAllApplications = async (searchParamString?: string) => {
  const response: ApiResponse<Application[], {}, { totalSize: number }> = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/application`,
    {
      searchParamString,
    }
  );

  return response;
};

export const getApplication = async (id: string) => {
  const response: ApiResponse<Application> = await fetchWithToken(`api/${CONSTANTS.API_VERSION}/application/${id}`);
  return response;
};

export const createApplication = async (schemeId: string, roundId: string) => {
  const response: ApiResponse<{ applicationId: string; formId: string }> = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/application`,
    {
      method: 'POST',
      body: {
        schemeId,
        roundId,
      },
    }
  );

  return response;
};

/**
 * Async function which performs a PATCH request to associate a new Organisation
 * with an Application.
 */
export const updateApplication = async (
  applicationId: string,
  options: {
    body: { [key: string]: string | number };
  }
) => {
  const { success, errors } = await fetchWithToken(`api/${CONSTANTS.API_VERSION}/application/${applicationId}`, {
    method: 'PATCH',
    body: options?.body,
  });

  return {
    success,
    errors,
  };
};

export const removeUserFromApplication = async (applicationId: string, participantId: string) => {
  const { success, errors } = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/application/${applicationId}/participant/${participantId}`,
    { method: 'DELETE' }
  );

  return {
    success,
    errors,
  };
};

export const submitApplicationToResearchOffice = async (applicationId: string) => {
  const { success, errors } = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/application/${applicationId}:submitToResearchOffice`,
    { method: 'POST' }
  );

  return {
    success,
    errors,
  };
};

export const submitApplicationToWellcome = async (applicationId: string) => {
  const { success, errors } = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/application/${applicationId}:submitToWellcome`,
    { method: 'POST' }
  );

  return {
    success,
    errors,
  };
};

export const returnApplicationToApplicant = async (applicationId: string) => {
  const { success, errors } = await fetchWithToken(
    `api/${CONSTANTS.API_VERSION}/application/${applicationId}:returnToApplicant`,
    { method: 'POST' }
  );

  return {
    success,
    errors,
  };
};
