import FocusTrap from 'focus-trap-react';
import { useRef, ReactNode, MouseEvent, TouchEvent, KeyboardEvent, Dispatch, SetStateAction } from 'react';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import useOnKeyDown from '../../hooks/useOnKeyDown';

type PopupProps = {
  children: ReactNode;
  summaryChildren: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
  summaryClassName?: string;
  summaryAriaLabel?: string;
  isDisabled?: boolean;
  'data-cy'?: string;
};

/**
 * Component for handling popup behaviour. It opens the popup, adds a focus trap around the popup, and closes the popup on escape key press and on outside click.
 *
 * The component consists of a `<details>` element and a corresponding `<summary>` element, the contents of which must be provided in the `summaryChildren` prop.
 *
 * @example
 * const summary = <p>Clickable popup label</p>
 * const [isOpen, setIsOpen] = useState(false);
 * return (
 *   <Popup summaryChildren={summary} isOpen={isOpen} setIsOpen={setIsOpen}>
 *    <p>Popup content</p>
 *   </Popup>
 * )
 */
export const Popup = (props: PopupProps) => {
  const ref = useRef();

  useOnClickOutside(ref, () => props.setIsOpen(false));
  useOnKeyDown(['Esc', 'Escape'], () => props.setIsOpen(false));

  const handleMenuOpenClose = (event: MouseEvent | TouchEvent | KeyboardEvent) => {
    if (props.isDisabled) return;

    event.preventDefault();
    props.setIsOpen(!props.isOpen);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (props.isDisabled) return;

    if (event.key === 'Enter') {
      props.setIsOpen(!props.isOpen);
    }
  };

  return (
    <FocusTrap
      active={props.isOpen}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        escapeDeactivates: true,
        onDeactivate: () => props.setIsOpen(false),
      }}
    >
      <details ref={ref} open={props.isOpen} className={props.className}>
        <summary
          className={props.summaryClassName}
          onClick={handleMenuOpenClose}
          onKeyDown={onKeyDown}
          aria-label={props.summaryAriaLabel}
          data-cy={props['data-cy']}
          role="button"
          tabIndex={0}
        >
          {props.summaryChildren}
        </summary>
        {props.children}
      </details>
    </FocusTrap>
  );
};

export default Popup;
