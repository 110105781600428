import useStatus from './useStatus';

import { User } from 'utils/api/status';

// Capitalise to match the UserRole type
export type PermissionType = 'OFFICE' | 'APPLICANT' | undefined;

const getPermissions = (user: User | undefined): PermissionType => {
  if (user === null) {
    return undefined;
  }

  if (user.isResearchOfficeUser) {
    return 'OFFICE';
  }

  return 'APPLICANT';
};

export const usePermissions = () => {
  const { user, isLoading } = useStatus();

  const permissions = getPermissions(user);

  return {
    permissions,
    isLoading,
  };
};

export default usePermissions;
