import { useRouter } from 'next/router';

import Pill from 'components/Pill';

import useApplicationOverview from 'hooks/useApplicationOverview';
import useApplicationParams from 'hooks/useApplicationParams';
import useCurrentParticipant from 'hooks/useCurrentParticipant';
import useExpertReview from 'hooks/useExpertReview';
import useExpertReviewParams from 'hooks/useExpertReviewParams';
import useRoleBasedSectionStatus from 'hooks/useRoleBasedSectionStatus';
import CONSTANTS from 'utils/constants';

export type StatusBadgeProps = {
  className?: string;
};

export type SectionStatusBadgeProps = StatusBadgeProps & {
  sectionId: string;
};

export const SectionStatusBadge = (props: SectionStatusBadgeProps) => {
  const { status, isLoading } = useRoleBasedSectionStatus(props.sectionId);

  if (isLoading) return;

  return (
    <Pill className={props.className} context="section">
      {status}
    </Pill>
  );
};

export const AccountProfileStatusBadge = (props: StatusBadgeProps) => {
  const { formId, applicationId } = useApplicationParams();
  const { user } = useCurrentParticipant(`${formId}/${applicationId}`);

  return (
    <Pill className={props.className} context="accountProfile">
      {user ? user.accountProfileStatus : 'Loading...'}
    </Pill>
  );
};

export const PositiveActionStatusBadge = (props: StatusBadgeProps) => {
  const { formId, applicationId } = useApplicationParams();
  const { data } = useApplicationOverview(`${formId}/${applicationId}`);

  const sectionStatus = data?.staticForm?.pageGroups[0].pages[0].status.applicantStatus;

  return (
    <Pill className={props.className} context="positiveAction">
      {data ? sectionStatus : 'Loading...'}
    </Pill>
  );
};

export const AcceleratorAwardsStatusBadge = (props: StatusBadgeProps) => {
  const { formId, applicationId } = useApplicationParams();
  const { data } = useApplicationOverview(`${formId}/${applicationId}`);

  const sectionStatus = data?.staticForm?.pageGroups[0].pages[0].status.applicantStatus;

  return (
    <Pill className={props.className} context="acceleratorAwards">
      {data ? sectionStatus : 'Loading...'}
    </Pill>
  );
};

export const ExpertReviewStatusBadge = (props: StatusBadgeProps) => {
  const { expertReviewId } = useExpertReviewParams();
  const { data: expertReview, isLoading } = useExpertReview(expertReviewId);

  if (isLoading || !expertReview) return;

  return (
    <Pill className={props.className} context="expertReview">
      {expertReview.status}
    </Pill>
  );
};

const StatusBadge = (props: StatusBadgeProps) => {
  const router = useRouter();
  const { sectionId } = useApplicationParams();

  /**
   * This is a workaround to allow us to display a participant's profile status in the same way as
   * we display section status'. The participant's profile status is not stored alongside
   * other sections, so we need to get it from elsewhere in the application overview query.
   *
   * The reason we are using the same component to display this is because of the way in which
   * the Page & PageHeader components are structured. We need to be able to display the status
   * of the participant's profile in the same way as we display the status of other sections.
   *
   * This is a temporary solution until we can refactor the Page & PageHeader components to
   * allow for more flexibility.
   */

  if (router.pathname.includes('personal-profile')) {
    return <AccountProfileStatusBadge className={props.className} />;
  }

  if (CONSTANTS.STATIC_FORM_SECTIONS[sectionId]?.staticFormId === 'pa') {
    return <PositiveActionStatusBadge className={props.className} />;
  }

  if (CONSTANTS.STATIC_FORM_SECTIONS[sectionId]?.staticFormId === 'aa') {
    return <AcceleratorAwardsStatusBadge className={props.className} />;
  }

  if (sectionId) {
    return <SectionStatusBadge className={props.className} sectionId={sectionId} />;
  }

  if (
    router.pathname.includes('expert-reviews/') &&
    !router.pathname.includes('/completed') &&
    !router.pathname.includes('/before-you-start')
  ) {
    return <ExpertReviewStatusBadge className={props.className} />;
  }
};

export default StatusBadge;
