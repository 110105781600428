import { useEffect, useState } from 'react';

type UsePreviousPagePathResponse = {
  storePathValues: () => void;
  previousPagePath: string | null;
};

export const usePreviousPagePath = (): UsePreviousPagePathResponse => {
  const [previousPagePath, setPreviousPagePath] = useState<string | null>(null);
  const storage = globalThis?.sessionStorage;

  // This is called from _app.tsx to ensure that the previous path is always stored
  const storePathValues = () => {
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', globalThis.location.pathname);
  };

  useEffect(() => {
    setPreviousPagePath(storage.getItem('prevPath'));
  }, []);

  return {
    storePathValues,
    previousPagePath,
  };
};

export default usePreviousPagePath;
