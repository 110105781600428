import cx from 'classnames';

import usePermissions from 'hooks/usePermissions';
import { getStatusLabel, StatusContext } from 'utils/status-labels';
import getStatusHasPartyPopper from 'utils/status-party-popper';
import { getStatusVariant } from 'utils/status-variants';

type PillProps = {
  context?: StatusContext;
  children: string;
  className?: string;
  customSubStatusLabel?: string;
};

export const Pill = (props: PillProps) => {
  const { permissions } = usePermissions();
  const statusLabel = getStatusLabel(props.children, props.context, permissions);
  const statusVariant = getStatusVariant(props.children, props.context);
  const hasPartyPopper = getStatusHasPartyPopper(props.children, props.context);

  return (
    <>
      <span
        className={cx(
          'rounded-[100px] whitespace-nowrap px-staticXs py-staticXxs xs:px-staticMd xs:py-staticXs border-1 text-body-xs font-medium inline-block',
          props.className,
          {
            'bg-green-05 border-green-10 text-green-70': statusVariant === 'GREEN',
            'bg-blue-05 border-blue-10 text-blue-60': statusVariant === 'BLUE',
            'bg-orange-10 border-orange-20 text-orange-70': statusVariant === 'ORANGE',
            'bg-grey-05 border-grey-10 text-grey-70': statusVariant === 'GREY',
            'bg-amber-10 border-amber-20 text-amber-80': statusVariant === 'AMBER',
            'border-grey-20 text-grey-70': !statusLabel,
          }
        )}
        data-cy="status-pill"
      >
        {hasPartyPopper && (
          <span className="mr-staticXxs" aria-hidden="true" role="img">
            🎉
          </span>
        )}
        {statusLabel || props.children}
      </span>
      {props.customSubStatusLabel && <span className="text-body-sm ml-staticMd">{props.customSubStatusLabel}</span>}
    </>
  );
};

export default Pill;
