import { ReactNode } from 'react';

import useApplicationRole from 'hooks/useApplicationRole';
import type { PermissionType } from 'hooks/usePermissions';
import usePermissions from 'hooks/usePermissions';
import type { UserRole } from 'utils/api/status';

type RestrictedContentProps = {
  children: ReactNode;
  fallback?: ReactNode;
  permission: PermissionType | UserRole;
};

export const RestrictedContent = (props: RestrictedContentProps) => {
  const { permissions, isLoading: isLoadingPermission } = usePermissions();
  const { role, isLoading: isLoadingRole } = useApplicationRole();

  const isLoading = isLoadingPermission || isLoadingRole;

  if ([permissions, role].includes(props.permission)) {
    return <>{props.children}</>;
  }

  if (props.fallback && !isLoading) {
    return <>{props.fallback}</>;
  }

  return null;
};

export default RestrictedContent;
