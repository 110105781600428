import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import { useContext } from 'react';

import Toast from './Toast';

import { NotificationsContext } from 'components/NotificationsContext';
import type { notificationsListItem } from 'components/NotificationsContext';

const loadFeatures = () => import('utils/framer-motion').then((res) => res.default);

const ToastList = () => {
  const { notifications, deleteToast, stopAutohideTimer, startAutohideTimer } = useContext(NotificationsContext) || {};

  return (
    <ul
      className="fixed list-none top-0 right-0 z-50 pr-staticMd md:pr-staticXl pt-staticMd md:pt-staticXl"
      data-cy="toast-list"
    >
      <LazyMotion features={loadFeatures}>
        <AnimatePresence>
          {notifications &&
            notifications.map((toast: notificationsListItem) => (
              <m.li
                className="p-0 m-0"
                initial={{
                  opacity: 0,
                  height: 0,
                  y: -150,
                }}
                animate={{ opacity: 1, height: 'auto', y: 0 }}
                exit={{ opacity: 0, height: 0, y: 0 }}
                transition={{ type: 'spring', duration: 0.8 }}
                key={toast.id}
              >
                <div className="pb-staticSm md:pb-staticMd">
                  <Toast
                    toastId={toast.id}
                    onClose={() => deleteToast(toast.id)}
                    title={{
                      text: `${toast.notificationTitle}`,
                    }}
                    notificationVariant={toast.variant}
                    onMouseEnter={() => {
                      stopAutohideTimer(toast.id);
                    }}
                    onMouseLeave={() => {
                      if (toast.autoHide) {
                        startAutohideTimer(toast.id);
                      }
                    }}
                  >
                    {toast.notificationText}
                  </Toast>
                </div>
              </m.li>
            ))}
        </AnimatePresence>
      </LazyMotion>
    </ul>
  );
};

export default ToastList;
