import { DisplayStatus } from './api/application';
import { AwardedGrantStatus } from './api/awarded-grant';
import { StatusContext } from './status-labels';

const applicationApplicantPartyPopperStatuses: DisplayStatus[] = [
  'APPROVED',
  'AWARD_OFFERED',
  'AWARD_ACCEPTED',
  'AWARD_STARTING_SOON',
  'ACTIVE',
  'COMPLETED',
  'CLOSED',
];

const awardedGrantPartyPopperApplicantStatuses: AwardedGrantStatus[] = ['AWARD_OFFERED', 'AWARD_ACCEPTED'];
const awardedGrantPartyPopperResearchOfficeStatuses: AwardedGrantStatus[] = ['AWARD_OFFERED'];

const partyPopperStatusesByStatusVariant = {
  applicationApplicant: applicationApplicantPartyPopperStatuses,
  applicationResearchOffice: applicationApplicantPartyPopperStatuses,
  awardedGrantApplicant: awardedGrantPartyPopperApplicantStatuses,
  awardedGrantResearchOffice: awardedGrantPartyPopperResearchOfficeStatuses,
};

export const getStatusHasPartyPopper = (status: string, context: StatusContext) =>
  partyPopperStatusesByStatusVariant[context]?.includes(status);

export default getStatusHasPartyPopper;
