import cx from 'classnames';

type SkipLinkProps = {
  children: string;
  className?: string;
  href: string;
};

export const SkipLink = (props: SkipLinkProps) => (
  <a
    aria-hidden="false"
    className={cx('inline-block sr-only focus:not-sr-only focus:p-sm bg-amber-30 rounded-sm text-center z-[80]', {
      [props.className]: props.className,
    })}
    href={props.href}
  >
    {props.children}
  </a>
);

export default SkipLink;
