import cx from 'classnames';

import { TooltipPosition } from './Tooltip';

type TooltipArrowProps = {
  position: TooltipPosition;
  isShifting?: boolean;
  shiftingX?: number;
  shiftingY?: number;
};

export const TooltipArrow = ({ position, isShifting, shiftingX, shiftingY }: TooltipArrowProps) => {
  const isVertical = position === 'top' || position === 'bottom';

  return (
    <span
      className={cx(
        'block h-0 w-0 absolute border-b-[0.5rem] border-b-grey-80 border-l-[0.375rem] border-l-transparent border-r-[0.375rem] border-r-transparent',
        {
          'left-1/2 -top-sm': position === 'bottom',
          'left-1/2 -bottom-sm -rotate-180': position === 'top',
          'top-1/2 -left-sm -rotate-90 ': position === 'right',
          'top-1/2 -right-sm rotate-90': position === 'left',
          '-translate-x-1/2': !isShifting && isVertical,
          '-translate-y-1/2': !isShifting && !isVertical,
          'translate-x-1/2': isShifting && isVertical,
          'translate-y-1/2': isShifting && !isVertical,
        }
      )}
      style={isShifting && { left: isVertical && -shiftingX, top: !isVertical && -shiftingY }}
    />
  );
};

export default TooltipArrow;
