import { useCallback, useLayoutEffect } from 'react';

export const useScrollLock = () => {
  /*
   * When we want to lock the scroll (for example when displaying modals), we need to ensure vertical overflow is hidden,
   * and set the container position to fixed (to support iOS). To stop the page scrolling back to the top when we apply
   * position fixed to the body, we need to dynamically compensate for the offset. Finally, we need to compensate for the
   * potential disappearance of the scrollbar by adding padding to the body.
   */
  const lockScroll = useCallback(() => {
    // Add a data property to allow us to adjust padding and margin of other elements when scroll is locked
    document.body.dataset.scrollLock = 'true';
    document.body.style.overflow = 'hidden';

    // When we set overflow to hidden, the scrollbar disappears, so we need to compensate for that
    document.body.style.paddingRight = 'var(--scrollbar-compensation)';

    // On iOS, when we set position to fixed, the page will jump to the top, so we need to compensate for that
    const scrollOffset = window.pageYOffset;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollOffset}px`;
    document.body.style.width = '100%';
  }, []);

  const unlockScroll = useCallback(() => {
    const scrollOffset = document.body.style.top;
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';

    // We need to disable smooth scroll when replacing the offset, otherwise we'll get a jump
    document.documentElement.classList.add('normal-scroll');
    window.scrollTo(0, parseInt(scrollOffset || '0', 10) * -1);
    document.documentElement.classList.remove('normal-scroll');

    delete document.body.dataset.scrollLock;
  }, []);

  useLayoutEffect(() => {
    const scrollBarCompensation = window.innerWidth - document.body.offsetWidth; // TODO: Not calculating correctly, always 0
    document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`);
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};

export default useScrollLock;
