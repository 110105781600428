import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import { LoadingPanel } from 'components/Spinner/Spinner';

import useStatus from 'hooks/useStatus';
import { UNPROTECTED_URLS } from 'utils/url-library';

type SessionProps = {
  children: ReactNode;
};

export const Session = (props: SessionProps) => {
  const router = useRouter();
  const { user, isLoading } = useStatus();

  const isPublicRoute = (() => UNPROTECTED_URLS.some((route) => router.pathname.startsWith(route)))();

  useEffect(() => {
    if (isLoading) return;

    if (user === null && !isPublicRoute) {
      router.replace(`${process.env.OAUTH_REDIRECT_URL}&state=${router.asPath}`);
    }
  }, [user, isLoading]);

  /**
   * If we don't have a user and we're on a protected route there will be a redirect to the login page.
   * This will cause a flash of the page content before the redirect happens. To avoid this, we're doing
   * the second check here.
   *
   * @todo This, in a less hacky way.
   */
  if (isLoading || (user === null && !isPublicRoute)) {
    return (
      <main>
        <LoadingPanel className="h-100">Loading...</LoadingPanel>
      </main>
    );
  }

  return <>{props.children}</>;
};

export default Session;
