export const URL_LIBRARY = {
  EXTERNAL: {
    CIVIC_COOKIE_SCRIPT: 'https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js',
    MS_EDGE_COOKIES:
      'https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd',
    MS_IE_COOKIES:
      'https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d',
    CHROME_COOKIES: 'https://support.google.com/chrome/answer/95647?hl=en-GB',
    FF_COOKIES: 'https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox',
    SAFARI_MOBILE_COOKIES: 'https://support.apple.com/en-gb/105082',
    SAFARI_DESKTOP_COOKIES: 'https://support.apple.com/en-gb/guide/safari/sfri11471/mac',
    OPERA_COOKIES: 'https://help.opera.com/en/latest/web-preferences/',
    GA_COOKIES: 'https://support.google.com/analytics/answer/6004245',
  },
  FUNDING_PLATFORM: {
    AWARDED_GRANTS: '/awarded-grants',
    APPLICATIONS: '/applications',
    EXPERT_REVIEWS: '/expert-reviews',
    COMMITTEES: '/committees',
    EXPERT_REVIEW_INVITATION: '/expert-review-invitation',
    FORGOT_PASSWORD: {
      HOME: '/forgot-password',
      CONFIRM: '/forgot-password/confirm',
      RESET: '/forgot-password/reset',
      SUCCESS: '/forgot-password/success',
    },
    HOME: '/',
    LOGOUT: {
      HOME: '/logout',
      SUCCESS: '/logout/success',
      SALESFORCE: `https://${process.env.NEXT_PUBLIC_FUNPLAT_SALESFORCE_DOMAIN}/secur/logout.jsp`,
    },
    LOGIN: '/login',
    REVIEWS_AND_COMMITTEES: '/reviews-and-committees',
    SCHEMES: '/schemes',
    TEAM: '/organisation/manage',
    FINANCIAL_REPORTING: '/financial-reporting',
    USER: {
      MANAGE: {
        HOME: '/user/manage',
        BASIC_INFORMATION: '/user/manage/basic-information',
        DIVERSITY_MONITORING: '/user/manage/diversity-monitoring',
        CV: '/user/manage/cv',
      },
      SETTINGS: {
        HOME: '/user/settings',
        COMMUNICATION_PREFERENCES: '/user/settings/communication-preferences',
        CHANGE_PASSWORD: {
          HOME: '/user/settings/change-password',
          CONFIRM: '/user/settings/change-password/confirm',
          RESET: '/user/settings/change-password/reset',
          SUCCESS: '/user/settings/change-password/success',
        },
      },
    },
    REGISTER: {
      HOME: '/register',
      CONFIRM: '/register/confirm',
      SET_PASSWORD: '/register/set-password',
    },
    OAUTH_CALLBACK: '/oauth2/callback',
    HEALTH_CHECK: '/health-check',
    COOKIE_POLICY: '/cookie-policy',
  },
  WELLCOME_MAIN_SITE: {
    ACCESSIBILITY: 'https://wellcome.org/who-we-are/website-accessibility',
    CONTACT_US: 'https://wellcome.org/who-we-are/contact-us',
    CONTACT_US_FORM: 'https://wellcome.org/who-we-are/contact-us/general-enquiry',
    CONTACT_US_GRANT_TRACKER: 'https://wellcome.org/who-we-are/contact-us/online-grant-enquiry',
    COSTS_GRANT_HOLDER_CAN_CLAIM: 'https://wellcome.org/grant-funding/guidance/costs-grant-holders-can-claim',
    DISABILITY_SUPPORT: 'https://wellcome.org/grant-funding/guidance/disability-related-support-for-applicants',
    DISABILITY_SUPPORT_GRANT_HOLDERS:
      'https://wellcome.org/grant-funding/guidance/disability-related-support-for-grantholders',
    DISCOVERY_RESEARCH_FUNDING_REMIT: 'https://wellcome.org/grant-funding/guidance/discovery-research-schemes-remit',
    DISCOVERY_AWARDS_PAGE: 'https://wellcome.org/grant-funding/schemes/discovery-awards',
    GRANT_CONDITIONS: 'https://wellcome.org/grant-funding/guidance/grant-conditions',
    GRANT_FUNDING_ENDING_GUIDANCE: 'https://wellcome.org/grant-funding/guidance#ending-a-grant-1959',
    GRANT_FUNDING_GUIDANCE: 'https://wellcome.org/grant-funding/guidance',
    GRANT_FUNDING_POLICIES: 'https://wellcome.org/grant-funding/guidance/grant-funding-policies',
    GRANT_FUNDING_ROLES_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/wellcome-funding-applicants',
    GRANT_FUNDING_ELIGIBILITY_GUIDANCE:
      'https://wellcome.org/grant-funding/guidance/eligibility-information-grant-applicants',
    GRANT_FUNDING_SCHEMES: 'https://wellcome.org/grant-funding/schemes',
    GRANT_PAYMENTS_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/grant-payments',
    GRANT_PROGRESS_REPORTING: 'https://wellcome.org/grant-funding/guidance/how-report-grant-progress',
    HELP_AND_SUPPORT: 'https://wellcome.org/who-we-are/contact-us/funding-enquiry',
    HOME: 'https://wellcome.org',
    HOW_TO_MANAGE_GRANT_BUDGET: 'https://wellcome.org/grant-funding/guidance/how-to-manage-your-grant-budget',
    HOW_TO_WRITE_A_GRANT_APPLICATION:
      'https://wellcome.org/grant-funding/guidance/how-to-write-wellcome-grant-application',
    HOW_TO_TRANSFER_A_GRANT: 'https://wellcome.org/grant-funding/guidance/how-to-transfer-grant',
    MATERNITY_PATERNITY_LEAVE:
      'https://wellcome.org/grant-funding/guidance/maternity-paternity-adoption-and-shared-parental-leave',
    MODERN_SLAVERY_STATEMENT: 'https://wellcome.org/who-we-are/modern-slavery-statement',
    NO_COST_EXTENSION: 'https://wellcome.org/grant-funding/guidance/grant-end-dates#no-cost-extensions-9cfb',
    ONLINE_GRANT_ENQUIRY: 'https://wellcome.org/who-we-are/contact-us/online-grant-enquiry',
    PRIVACY_AND_TERMS: 'https://wellcome.org/who-we-are/privacy-and-terms',
    PRIVACY_AND_CONFIDENTIALITY: 'https://wellcome.org/grant-funding/privacy-and-confidentiality-wellcome-grants',
    SPONSOR_INFORMATION: 'https://wellcome.org/grant-funding/guidance/how-to-write-wellcome-grant-application',
    TELEPHONE: 'tel:00442076118888',
    AUTHORISED_APPROVERS_GUIDANCE:
      'https://wellcome.org/grant-funding/guidance/wellcome-funding-platform-guidance-research-offices',
    SICK_LEAVE: 'https://wellcome.org/grant-funding/guidance/sick-leave',
    SPONSORS_GUIDANCE:
      'https://wellcome.org/grant-funding/guidance/how-use-grant-tracker#sponsors-and-supervisors-9a9a',
    CO_APPLICANTS_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/how-use-grant-tracker#other-applicants-fcef',
    LEAD_APPLICANT_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/how-use-grant-tracker#lead-applicants-98dd',
    APPLICANT_ELIGIBILITY_GUIDANCE: 'https://wellcome.org/what-we-do/our-work/eligibility-information-grant-applicants',
    WHAT_WE_DONT_FUND: 'https://wellcome.org/grant-funding/guidance/what-we-dont-fund',
    CONFLICTS_OF_INTEREST: 'https://wellcome.org/grant-funding/conflicts-interest-policy-wellcome-funding-process',
    FUNDING_SYSTEM_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/wellcome-funding-system-guidance',
    WHEN_WE_PAY_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/grant-payments#when-we-pay-grants-2448',
    END_OF_GRANT_REPORTING_GUIDANCE: 'https://wellcome.org/grant-funding/guidance/end-grant-reporting',
    END_OF_GRANT_REPORTING_TEMPLATES:
      'https://wellcome.org/grant-funding/guidance/end-grant-reporting#end-of-grant-report-form-1671',
  },
  MAILTO: {
    FUNDING_SUPPORT: 'fundingsupport@wellcome.org',
    DATA_PROTECTION: 'dataprotection@wellcome.org',
  },
} as const;

export const UNPROTECTED_URLS: string[] = [
  URL_LIBRARY.FUNDING_PLATFORM.LOGIN,
  URL_LIBRARY.FUNDING_PLATFORM.LOGOUT.HOME,
  URL_LIBRARY.FUNDING_PLATFORM.EXPERT_REVIEW_INVITATION,
  URL_LIBRARY.FUNDING_PLATFORM.REGISTER.HOME,
  URL_LIBRARY.FUNDING_PLATFORM.OAUTH_CALLBACK,
  URL_LIBRARY.FUNDING_PLATFORM.FORGOT_PASSWORD.HOME,
  URL_LIBRARY.FUNDING_PLATFORM.FORGOT_PASSWORD.SUCCESS,
  URL_LIBRARY.FUNDING_PLATFORM.HEALTH_CHECK,
  URL_LIBRARY.FUNDING_PLATFORM.USER.SETTINGS.CHANGE_PASSWORD.SUCCESS,
];

export const getHomepageUrl = (isResearchOfficeUser: boolean, applications: any) => {
  if (isResearchOfficeUser) {
    return URL_LIBRARY.FUNDING_PLATFORM.APPLICATIONS;
  }

  return applications.length > 0 ? URL_LIBRARY.FUNDING_PLATFORM.APPLICATIONS : URL_LIBRARY.FUNDING_PLATFORM.SCHEMES;
};

export default URL_LIBRARY;
