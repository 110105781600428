/**
 * The intention is that these are the core components that are used to build
 * a standard layout. The idea is that this makes layouts more malleable whilst also
 * maintaining a degree of consistency.
 *
 * They are intended to replace the Page component - they effectively do the same thing
 * by extracting its constituent parts.
 *
 * @see {@link https://www.tldraw.com/v/NX3_c_7vJHGoDg1AgSY_dPj_Z0} for a diagram of the layout
 */
export { default as PageWrapper } from './PageWrapper';
export { default as MainContent } from './MainContent';
export { default as Container } from './Container';
export { default as ApplicationFormSidebar } from './ApplicationFormSidebar';
export { default as GlobalNav } from './GlobalNav';
export { default as MenuBar } from './MenuBar';
export { Grid, GridCell } from './Grid';
