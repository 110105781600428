import useSWR from 'swr';

import getStatus from 'utils/api/status';

export const useStatus = () => {
  const response = useSWR(`api/status`, getStatus, {
    revalidateOnFocus: true,
  });

  return {
    ...response,
    data: response.data?.data,
    user: response.data?.data?.user,
    isLoading: !response.data && !response.error,
  };
};

export default useStatus;
