import cx from 'classnames';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';

import PageLevelBanner from './PageLevelBanner';

import FormContext from 'components/FormContext';

import useShowGlobalBanner from 'hooks/useShowGlobalBanner';
import { PlannedDowntime } from 'utils/api/status';
import { formatDateTime } from 'utils/dates';

type GlobalBannerProps = {
  className?: string;
  plannedDownTime: PlannedDowntime;
};

const BANNER_TITLE = 'Planned maintenance happening soon';
const BANNER_MESSAGE = 'You won’t be able to use Wellcome Funding from ';

const GlobalBanner = (props: GlobalBannerProps) => {
  const { isVisible, hideBanner } = useShowGlobalBanner();
  const [isActive, setActive] = useState(false);
  const { ref } = useContext(FormContext);

  useEffect(() => {
    const canShow = isVisible && ref === null;

    setActive(canShow);
  });

  if (!isActive) return null;

  const isSameDay = dayjs(props.plannedDownTime.start).isSame(props.plannedDownTime.end, 'day');

  let BANNER_DATE_TIME = '';

  if (isSameDay) {
    BANNER_DATE_TIME = `${formatDateTime(props.plannedDownTime.start, { type: 'time' })}-${formatDateTime(
      props.plannedDownTime.end,
      {
        type: 'time',
        showUkDaylightSavingsSuffix: true,
      }
    )} on ${formatDateTime(props.plannedDownTime.start, {
      customFormat: {
        day: '2-digit',
        month: 'long',
      },
    })}.`;
  } else {
    BANNER_DATE_TIME = `${formatDateTime(props.plannedDownTime.start, {
      type: 'time',
      showUkDaylightSavingsSuffix: true,
    })}, ${formatDateTime(props.plannedDownTime.start, {
      customFormat: {
        day: '2-digit',
        month: 'long',
      },
    })} to around ${formatDateTime(props.plannedDownTime.end, {
      type: 'time',
      showUkDaylightSavingsSuffix: true,
    })}, ${formatDateTime(props.plannedDownTime.end, {
      customFormat: {
        day: '2-digit',
        month: 'long',
      },
    })}.`;
  }

  return (
    <div className={cx(props.className)}>
      <PageLevelBanner notificationVariant="information" onClose={() => hideBanner()}>
        <p className="text-body-md text-grey-80">
          <span className="text-blue-80 font-bold">{BANNER_TITLE} </span>
          {BANNER_MESSAGE}
          {BANNER_DATE_TIME}
        </p>
      </PageLevelBanner>
    </div>
  );
};

export default GlobalBanner;
