import cx from 'classnames';
import { ReactNode } from 'react';

import { TooltipPosition } from './Tooltip';
import TooltipArrow from './TooltipArrow';

type TooltipDialogProps = {
  position: TooltipPosition;
  isShifting?: boolean;
  reference?: any;
  className?: string;
  shiftingX?: number;
  shiftingY?: number;
  id: string;
  children: ReactNode;
};

export const TooltipDialog = ({
  position,
  isShifting,
  reference,
  shiftingX,
  shiftingY,
  className,
  id,
  children,
}: TooltipDialogProps) => {
  const isVertical = position === 'top' || position === 'bottom';

  return (
    <div
      className={cx(
        'w-max max-w-[18rem] absolute z-20 py-sm px-md bg-grey-80 text-grey-05 text-body-sm rounded-sm',
        {
          'left-1/2 top-full mt-[0.75rem]': position === 'bottom',
          'left-1/2 bottom-full mb-[0.75rem]': position === 'top',
          'top-1/2 left-full ml-[1.25rem]': position === 'right',
          'top-1/2 right-full mr-[1.25rem]': position === 'left',
          '-translate-x-1/2': !isShifting && isVertical,
          '-translate-y-1/2': !isShifting && !isVertical,
        },
        className
      )}
      id={id}
      ref={reference}
      role="tooltip"
      style={isShifting && { left: isVertical && shiftingX, top: !isVertical && shiftingY }}
    >
      <TooltipArrow position={position} isShifting={isShifting} shiftingX={shiftingX} shiftingY={shiftingY} />
      {children}
    </div>
  );
};

export default TooltipDialog;
