import cx from 'classnames';
import { useContext } from 'react';

import MenuContext from 'components/MenuContext';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  maxWidth: 'sm' | 'md' | 'full';
};

const Container = (props: ContainerProps) => {
  const { isMenuLockedOpen, isMenuVisible, isFormNavVisible } = useContext(MenuContext);
  const hasMenu = (isMenuLockedOpen && isMenuVisible) || isFormNavVisible;

  return (
    <div
      className={cx('w-full mx-auto px-[5%] md:px-responsiveLg', props.className, {
        'max-w-[720px]': props.maxWidth === 'sm',
        'max-w-[1200px]': props.maxWidth === 'md',
        'max-w-auto': props.maxWidth === 'full',
        /**
         * @todo remove this complex logic, we should be able to apply max-widths/padding to the container
         * in a more straightforward way.
         *
         * @see {@link https://github.com/wellcometrust/corporate/issues/11508}
         */
        'xl:px-0 !max-w-[1210px]': !hasMenu && props.maxWidth === 'md',
      })}
    >
      {props.children}
    </div>
  );
};

export default Container;
