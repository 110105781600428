import { useState } from 'react';

import FormContext from './FormContext';
import type { FormContextProps } from './FormContext';

export const FormContextProvider = ({ children, processFile = undefined, removeFile = undefined }) => {
  const [focus, setFocus] = useState<FormContextProps['focus']>(null);
  const [formIsDirty, setFormIsDirty] = useState<FormContextProps['formIsDirty']>(false);
  const [initialFormData, setInitialFormData] = useState<FormContextProps['initialFormData']>(null);
  const [customFieldErrors, setCustomFieldErrors] = useState<FormContextProps['customFieldErrors']>(null);
  const [isTestForm, setIsTestForm] = useState<FormContextProps['isTestForm']>(null);
  const [ref, setRef] = useState<FormContextProps['ref']>(null);

  return (
    <FormContext.Provider
      value={{
        customFieldErrors,
        setCustomFieldErrors,
        focus,
        setFocus,
        formIsDirty,
        setFormIsDirty,
        initialFormData,
        setInitialFormData,
        isTestForm,
        setIsTestForm,
        ref,
        setRef,
        processFile,
        removeFile,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContextProvider;
