/**
 * @file Hook to allow function to be called if a specific key is pressed
 */
import { useEffect } from 'react';

const useOnKeyDown = (key: KeyboardEvent['key'] | Array<KeyboardEvent['key']>, handler: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((Array.isArray(key) && key.includes(event.key)) || event.key === key) {
        handler();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handler, key]);
};

export default useOnKeyDown;
