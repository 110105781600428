import { Icon } from '@wellcometrust/design-system';
import { IconProps } from '@wellcometrust/design-system/dist/components/Icon/Icon';
import cx from 'classnames';
import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes } from 'react';

type FancyLinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps & {
    href: LinkProps['href'];
    icon?: {
      beforeText?: boolean;
      className?: string;
      name: IconProps['name'];
      shouldAnimate?: boolean;
    };
    metaText?: string;
    spanText?: string;
    textClassName?: string;
    hideUnderline?: boolean;
    'data-cy'?: string;
    'aria-labelledby'?: string;
  };

export const FancyLink = (props: FancyLinkProps) => {
  const iconClassName = cx(props.icon?.className, {
    'c-fancy-link__animated-icon--right': props.icon?.shouldAnimate && !props.icon?.beforeText,
    'c-fancy-link__animated-icon--left': props.icon?.shouldAnimate && props.icon?.beforeText,
  });

  return (
    <Link
      className={cx('c-fancy-link', {
        [props.className]: props.className,
      })}
      href={props.href}
      target={props.target}
      onClick={props.onClick}
      data-cy={props['data-cy']}
      aria-labelledby={props['aria-labelledby']}
    >
      {props.icon && props.icon.beforeText && (
        <span className="mr-staticXs">
          <Icon name={props.icon.name} className={iconClassName} />
        </span>
      )}
      <span>
        <span
          className={cx({
            'c-fancy-link__text': !props.hideUnderline,
            [props.textClassName]: props.textClassName,
          })}
        >
          {props.children}
        </span>
        {props.metaText && <span className="text-grey-60 text-body-xs ml-staticXs">{props.metaText}</span>}
        {props.spanText && <span>{props.spanText}</span>}
      </span>
      {props.icon && !props.icon.beforeText && (
        <span className="ml-staticXs">
          <Icon name={props.icon.name} className={iconClassName} />
        </span>
      )}
    </Link>
  );
};

export default FancyLink;
