import { DetailedAwardedGrantStatus } from './api/awarded-grant';

import type {
  ApplicationStatus,
  DisplayStatus,
  NotProgressedStatusSf,
  SubmissionStatusSf,
} from 'utils/api/application';

/*
 * This function generates a status as viewed in the Funding Platform from the status and
 * submissionStatus values returned in the API. These statuses are then mapped to readable
 * values differently for Research Office users and Applicants.
 */
export const mapSalesforceStatusToApplicationStatus = (
  status: DisplayStatus,
  submissionStatus: SubmissionStatusSf | '',
  notProgressedStatus: NotProgressedStatusSf
): ApplicationStatus => {
  if (status === 'STARTED') return submissionStatus || 'WITH_APPLICANT';
  if (status === 'NOT_PROGRESSED') return notProgressedStatus || 'Unsuccessful';

  return status;
};

export const isStatusAwaitingDecision = (status: DisplayStatus) => {
  const statuses: DisplayStatus[] = [
    'EXPERT_REVIEW',
    'SHORTLISTING',
    'DECISION',
    'DECISION_INTERVIEW',
    'DECISION_RECOMMENDATION',
  ];
  return statuses.includes(status);
};

export const isStatusPostAward = (status: DisplayStatus) => {
  const statuses: DisplayStatus[] = [
    'APPROVED',
    'AWARD_OFFERED',
    'AWARD_ACCEPTED',
    'AWARD_STARTING_SOON',
    'ACTIVE',
    'COMPLETED',
    'CLOSED',
  ];

  return statuses.includes(status);
};

export const isStatusPastGrantStartDate = (status: DisplayStatus) => {
  const statuses: DisplayStatus[] = ['ACTIVE', 'COMPLETED', 'CLOSED'];
  return statuses.includes(status);
};

export const isStatusPastGrantEndDate = (status: DisplayStatus | DetailedAwardedGrantStatus) => {
  const statuses: Array<DisplayStatus | DetailedAwardedGrantStatus> = ['COMPLETED', 'CLOSED'];
  return statuses.includes(status);
};

export const isAwardedGrantStatus = (status: DisplayStatus | DetailedAwardedGrantStatus) => {
  const statuses: Array<DisplayStatus | DetailedAwardedGrantStatus> = [
    'AWARD_OFFERED',
    'AWARD_ACCEPTED',
    'AWARD_STARTING_SOON',
    'ACTIVE',
    'ENDED',
    'ENDING_SOON',
    'COMPLETED',
    'CLOSED',
    'REPORT_DUE',
  ];

  return statuses.includes(status);
};
