/**
 * @file Handles logging error messages to our error tracking service (Sentry).
 */
import * as Sentry from '@sentry/nextjs';

export const reportError = (error: unknown, context?: object) => {
  Sentry.captureException(error, {
    extra: {
      ...context,
    },
  });
};

export default reportError;
