import cx from 'classnames';
import { ReactNode } from 'react';

type SpinnerProps = {
  children?: ReactNode;
  className?: string;
  size?: 'sm' | 'md';
};

export const LoadingPanel = (props: SpinnerProps) => (
  <div className={cx('w-full flex justify-center items-center', { [props.className]: props.className })}>
    <Spinner size={props.size}>{props.children}</Spinner>
  </div>
);

export const Spinner = ({ children, className, size = 'md' }: SpinnerProps) => (
  <div className={className}>
    <div
      className={cx('inline-block align-middle relative', {
        'w-8 h-8': size === 'md',
        'w-[24px] h-[24px]': size === 'sm',
      })}
    >
      <div
        className={cx('absolute border-blue-10 border-t-blue-70 rounded-full animate-spin h-full w-full', {
          'border-2': size === 'sm',
          'border-4': size === 'md',
        })}
      />
    </div>
    {children && <span className="align-middle ml-sm">{children}</span>}
  </div>
);

export default Spinner;
