import cx from 'classnames';
import { ReactElement } from 'react';

import FancyHeading from 'components/FancyHeading';
import StatusBadge from 'components/StatusBadge';

type PageHeaderProps = {
  children?: ReactElement | ReactElement[];
  className?: string;
  hideStatusBadge?: boolean;
  title: string | ReactElement;
  hasUnderline?: boolean;
  fontSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

export const PageHeader = (props: PageHeaderProps) => (
  <header className={cx('pt-responsiveLg', { [props.className]: props.className })}>
    {!props.hideStatusBadge && <StatusBadge className="mb-responsiveSm" />}
    <FancyHeading
      className={cx({ 'mb-0': !props.children })}
      element="h1"
      fontFamily="secondary"
      fontSize={props.fontSize || 'xl'}
      hasUnderline={props.hasUnderline && Boolean(props.title)}
    >
      {props.title}
    </FancyHeading>
    {props.children}
  </header>
);

export default PageHeader;
